import React, {useState} from "react";
import './index.scss';
import {ReactComponent as UncheckedSVGBlue} from '../../../assets/check-box-uncheck-blue.svg';
import {ReactComponent as CheckedSVGBlue} from '../../../assets/check-box-checked-blue.svg';
import {ReactComponent as UncheckedSVGRed} from '../../../assets/check-box-uncheck-red.svg';
import {ReactComponent as CheckedSVGRed} from '../../../assets/check-box-checked-red.svg';
import {ReactComponent as CheckedSVGDisabled} from '../../../assets/check-box-checked-disable.svg';
import {ReactComponent as UncheckedSVGDisabled} from '../../../assets/check-box-uncheck-disable.svg';

interface SelectionQuestionsProps {
    question: string;
    options: Array<String>;
    value: String[];
    setValue: (value: any | null) => void;
    readOnly: boolean;
}

interface AnimatedCheckboxProps {
    checked: boolean;
    setChecked: (value: boolean) => void;
    animating: boolean;
    setAnimating: (value: boolean) => void;
    color: string;
    disabled: boolean;
}

const AnimatedCheckbox: React.FC<AnimatedCheckboxProps> = ({checked, setChecked, animating, setAnimating, color, disabled}) => {
    const handleClick = () => {
        if (!animating && !disabled) {
            setAnimating(true);
            setTimeout(() => {
                setChecked(!checked);
                setTimeout(() => {
                    setAnimating(false);
                }, 300);
            }, 300);
        }
    };

    return (
        <div onClick={handleClick} className={`checkbox ${animating ? 'animating' : 'normal'}`}>
            {disabled? (checked ? <CheckedSVGDisabled/> : <UncheckedSVGDisabled/>) : (color === 'red' ? (checked ? <CheckedSVGRed/> : <UncheckedSVGRed/>) : (checked ? <CheckedSVGBlue/> :
                <UncheckedSVGBlue/>))}
        </div>
    );
};


const MultiSelectQuestions = React.forwardRef<HTMLDivElement, SelectionQuestionsProps>(({
                                                                                            question,
                                                                                            options,
                                                                                            setValue,
                                                                                            value,
    readOnly
                                                                                        }, ref) => {
        const initCheckboxValue = options.map(option => {
            return ({optionValue: option, animating: false})
        })
        const [checkboxes, setCheckboxes] = useState(initCheckboxValue);


        const findOtherValue = (options: String[], value: String[]) => {
            const difference = value?.filter(element => !options.includes(element)) || [];
            if (difference?.length > 0) {
                return difference[0]
            } else {
                return undefined;
            }
        }

        const handleOtherChange = (e: boolean) => {
            if (e) {
                setValue([...(value?.filter((v) => options.includes(v)) || []), '']);
            } else {
                setValue([...(value?.filter((v) => options.includes(v)) || [])]);
            }
        };

        const handleOtherInput = (e: React.ChangeEvent<HTMLInputElement>) => {
            setValue([...(value?.filter((v) => options.includes(v)) || []), e.target.value]);
        };

        const handleSetChecked = (index: number, v: boolean) => {
            if (v) {
                //@ts-ignore
                setValue([...value || [], checkboxes[index].optionValue])
            } else {
                const newValue = value;
                const toDeleteIndex = newValue.findIndex((u) => u === checkboxes[index].optionValue)
                newValue.splice(toDeleteIndex, 1);
                setValue(newValue)
            }
        };


        const handleSetAnimating = (index: number, value: boolean) => {
            const newCheckboxes = [...checkboxes];
            newCheckboxes[index].animating = value;
            setCheckboxes(newCheckboxes);
        };

        const otherChecked = findOtherValue(options, value) !== undefined;
        return (
            <div className={"selection-question question"}>
                <div className={'question-text'} ref={ref}>{question}</div>
                {checkboxes.map((checkbox, index) => {
                    if (checkbox.optionValue.includes("其他")) {
                        return (
                            <>
                                <div key={index} className={'option option-other'}>
                                    <div className="checkbox-container">
                                        <AnimatedCheckbox
                                            checked={otherChecked}
                                            setChecked={(v) => {
                                                handleOtherChange(v)
                                            }}
                                            animating={checkbox.animating}
                                            setAnimating={(value) => handleSetAnimating(index, value)}
                                            color={index % 2 === 0 ? 'red' : 'blue'}
                                            disabled={readOnly}
                                        />
                                    </div>
                                    <span className={'option-text'}>{options[index]}</span>
                                </div>
                                {otherChecked &&
                                    // @ts-ignore
                                    <input disabled={readOnly} className={'question-input'} value={findOtherValue(options, value) || ''}
                                           onChange={handleOtherInput}/>}
                            </>)
                    } else {
                        return (<div key={index} className={'option'}>
                            <div className="checkbox-container">
                                <AnimatedCheckbox
                                    disabled={readOnly}
                                    checked={value?.includes(checkboxes[index].optionValue)}
                                    setChecked={(v) => {
                                        handleSetChecked(index, v)
                                    }}
                                    animating={checkbox.animating}
                                    setAnimating={(value) => handleSetAnimating(index, value)}
                                    color={index % 2 === 0 ? 'red' : 'blue'}
                                />
                            </div>
                            <span className={'option-text'}>{options[index]}</span>
                        </div>)
                    }
                })}
            </div>
        )
    }
)

export default MultiSelectQuestions;