import React from "react";
import './index.scss';

interface ParagraphQuestionsProps {
    question: string;
    value: string;
    setValue: (value: string | null) => void;
    readOnly: boolean ;
}


const ParagraphQuestions = React.forwardRef<HTMLDivElement, ParagraphQuestionsProps>(({ question, value, setValue, readOnly }, ref) => {
    const handleInputChange = (event: any) => {
        if(event.target.value) {
            setValue(event.target.value);
        } else {
            setValue(null);
        }
    }
    return (
    <div className={"blank-fill-question question"} ref={ref}>
        <div className={'question-text'}>{question}</div>
        <div className={'input-wrapper'}>
            <textarea rows={8} className={'textarea-input'} placeholder={'Answer'} value={value}
                       disabled={readOnly} onChange={handleInputChange} />
        </div>
    </div>)
})

export default ParagraphQuestions;