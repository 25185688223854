import React from "react";
import './index.scss'
import SelectionQuestions from "../SelectionQuestions";
import BlankFillingQuestions from "../BlankFillingQuestions";
import MultiSelectQuestions from "../MultiSelectQuestions";

interface ForthSurveyProps {
    response: any;
    setResponse: Function;
    readOnly: boolean;
}

//青少年天赋探究
const ForthSurvey = React.forwardRef<HTMLDivElement, ForthSurveyProps>(({response, setResponse, readOnly}, ref: any) => {
    return <div className={"survey-question"}>
        <BlankFillingQuestions readOnly={readOnly} key='1' question={"1. 姓名"}
                               ref={el => ref.current[0] = el}
                               setValue={(value) => {
                                   setResponse(1, value)
                               }} value={response[1] || null}/>

        <SelectionQuestions readOnly={readOnly} key='2' question={"2. 性别"} options={['男', '女']} ref={el => ref.current[1] = el}
                            setValue={(value) => {
                                setResponse(2, value)
                            }} value={response[2] || null}/>

        <BlankFillingQuestions readOnly={readOnly} key='3' question={"3. 年龄"}
                               ref={el => ref.current[2] = el}
                               setValue={(value) => {
                                   setResponse(3, value)
                               }} value={response[3] || null}/>

        <MultiSelectQuestions readOnly={readOnly} key='4' question={"4. 您认为自己在以下哪些领域表现出较强的能力? (可多选)"}
                              options={['深入钻研.', '逻辑思维.', '口头表达', '语言能力', '数学计算', '空间想象', '音乐节奏感', '身体协调性', '细微观察', '人际交往', '独处', '舞台表现力', '其他']}
                              ref={el => ref.current[3] = el} setValue={(value) => {
            setResponse(4, value)
        }} value={response[4] || null}/>

        <SelectionQuestions readOnly={readOnly} key='5' question={"5. 你是否喜欢团队合作?还是喜欢独立完成任务?"} options={['团队', '独立']}
                            ref={el => ref.current[4] = el}
                            setValue={(value) => {
                                setResponse(5, value)
                            }} value={response[5] || null}/>

        <SelectionQuestions readOnly={readOnly} key='6' question={"6. 喜欢跟朋友聚在一起,如果需要长时间单独工作,就会耗尽精力?"}
                            options={['是', '否']}
                            ref={el => ref.current[5] = el}
                            setValue={(value) => {
                                setResponse(6, value)
                            }} value={response[6] || null}/>

        <SelectionQuestions readOnly={readOnly} key='7' question={"7. 喜欢独处,你会希望自己一个人待一会,也能长时间专注?"}
                            options={['是', '否']}
                            ref={el => ref.current[6] = el}
                            setValue={(value) => {
                                setResponse(7, value)
                            }} value={response[7] || null}/>

        <SelectionQuestions readOnly={readOnly} key='8' question={"8. 你可以在任何场合表达和沟通还是只在特定场合特定情况?"}
                            options={['随时随地', '特定情况']}
                            ref={el => ref.current[7] = el}
                            setValue={(value) => {
                                setResponse(8, value)
                            }} value={response[8] || null}/>

        <SelectionQuestions readOnly={readOnly} key='9' question={"9. 与人交流时会越说越来劲还是说多了会感到疲倦不想再说话?"}
                            options={['来劲', '疲倦']}
                            ref={el => ref.current[8] = el}
                            setValue={(value) => {
                                setResponse(9, value)
                            }} value={response[9] || null}/>

        <SelectionQuestions readOnly={readOnly} key='10' question={"10. 你想成为某个领域内的专家吗?"} options={['是', '否']}
                            ref={el => ref.current[9] = el}
                            setValue={(value) => {
                                setResponse(10, value)
                            }} value={response[10] || null}/>

        <SelectionQuestions readOnly={readOnly} key='11' question={"11. 你更喜欢深入研究一个课题还是广泛涉猎什么都感兴趣?"}
                            options={['专一', '广泛']}
                            ref={el => ref.current[10] = el}
                            setValue={(value) => {
                                setResponse(11, value)
                            }} value={response[11] || null}/>

        <SelectionQuestions readOnly={readOnly} key='12' question={"12. 你是否常常深入思考某个问题而难以自拔?"} options={['是', '否']}
                            ref={el => ref.current[11] = el}
                            setValue={(value) => {
                                setResponse(12, value)
                            }} value={response[12] || null}/>

        <SelectionQuestions readOnly={readOnly} key='13' question={"13. 你容易在不同的多项事情中切换吗?或者说你能同时做几件不同的事情吗?"}
                            options={['是', '否', '中间']}
                            ref={el => ref.current[12] = el}
                            setValue={(value) => {
                                setResponse(13, value)
                            }} value={response[13] || null}/>

        <SelectionQuestions readOnly={readOnly} key='14' question={"14. 你享受挑战吗?在突发事件时你能快速做出决定吗?"}
                            options={['是', '否']}
                            ref={el => ref.current[13] = el}
                            setValue={(value) => {
                                setResponse(14, value)
                            }} value={response[14] || null}/>

        <SelectionQuestions readOnly={readOnly} key='15' question={"15. 你喜欢临时变更计划吗?会为了某些事情马上挤出时间吗?"}
                            options={['是', '否', '看情况']}
                            ref={el => ref.current[14] = el}
                            setValue={(value) => {
                                setResponse(15, value)
                            }} value={response[15] || null}/>

        <SelectionQuestions readOnly={readOnly} key='16' question={"16. 还是喜欢制定长时间的计划，不喜欢随机的改变?"} options={['是', '否']}
                            ref={el => ref.current[15] = el}
                            setValue={(value) => {
                                setResponse(16, value)
                            }} value={response[16] || null}/>

        <SelectionQuestions readOnly={readOnly} key='17' question={"17. 对于不能马上看到结果或回报的努力,你:"}
                            options={['无所谓,慢慢来,总有一天会有结果的.', '可以等一段时间.', '不能等,没结果就放弃.']}
                            ref={el => ref.current[16] = el}
                            setValue={(value) => {
                                setResponse(17, value)
                            }} value={response[17] || null}/>

        <SelectionQuestions readOnly={readOnly} key='18' question={"18. 在做决定和判断时,你是:"}
                            options={['喜欢快速做出选择和决定的人.', '需要长时间反复思考的人.', '中间']}
                            ref={el => ref.current[17] = el}
                            setValue={(value) => {
                                setResponse(18, value)
                            }} value={response[18] || null}/>

        <SelectionQuestions readOnly={readOnly} key='19' question={"19. 你喜欢按计划遵守规矩的做事?"} options={['是', '否', '有时不会']}
                            ref={el => ref.current[18] = el}
                            setValue={(value) => {
                                setResponse(19, value)
                            }} value={response[19] || null}/>

        <SelectionQuestions readOnly={readOnly} key='20' question={"20. 一个计划,对你来说多少年算是长远计划?"} options={['1年', '3年', '5年', '10年', '15年']}
                            ref={el => ref.current[19] = el}
                            setValue={(value) => {
                                setResponse(20, value)
                            }} value={response[20] || null}/>

        <SelectionQuestions readOnly={readOnly} key='21' question={"21. 你对死记硬背的内容或事情感到:"} options={['无聊', '享受', '无所谓']}
                            ref={el => ref.current[20] = el}
                            setValue={(value) => {
                                setResponse(21, value)
                            }} value={response[21] || null}/>

        <SelectionQuestions readOnly={readOnly} key='22' question={"22. 不喜欢追究细节,差不多就好?"} options={['是', '否']}
                            ref={el => ref.current[21] = el}
                            setValue={(value) => {
                                setResponse(22, value)
                            }} value={response[22] || null}/>

        <SelectionQuestions readOnly={readOnly} key='23' question={"23. 讨论时能够快速总结归纳,说到关键点,能直接抢断出结果结论,换句话说,你能很快地找出故事的重要部分,然后告诉大家故事会怎么结束."} options={['是', '否']}
                            ref={el => ref.current[22] = el}
                            setValue={(value) => {
                                setResponse(23, value)
                            }} value={response[23] || null}/>

        <SelectionQuestions readOnly={readOnly} key='24' question={"24. 你能看到事件的逻辑后果,预测和计划事情的发生."} options={['是','一般','否']}
                            ref={el => ref.current[23] = el}
                            setValue={(value) => {
                                setResponse(24, value)
                            }} value={response[24] || null}/>

        <SelectionQuestions readOnly={readOnly} key='25' question={"25. 你能将想法,信息或事物按逻辑顺序排列,你喜欢按顺序排列做事,不喜欢遗漏步骤."} options={['是','一般','否']}
                            ref={el => ref.current[24] = el}
                            setValue={(value) => {
                                setResponse(25, value)
                            }} value={response[25] || null}/>

        <SelectionQuestions readOnly={readOnly} key='26' question={"26. 任何时候都有大量的想法在你的脑海中流淌,对于你来说,很容易想出多种解决问题的办法,或不同方式说服别人接受你的观点,以及在别人不理解的情况下想出替代的方式来解释,这都是相对容易的."} options={['是','一般','否']}
                            ref={el => ref.current[25] = el}
                            setValue={(value) => {
                                setResponse(26, value)
                            }} value={response[26] || null}/>

        <SelectionQuestions readOnly={readOnly} key='27' question={"27. 喜欢例行公事或死记硬背或简单重复的抄写."} options={['是','一般','否']}
                            ref={el => ref.current[26] = el}
                            setValue={(value) => {
                                setResponse(27, value)
                            }} value={response[27] || null}/>

        <SelectionQuestions readOnly={readOnly} key='28' question={"28. 无法长时间专注在一个作业上,会烦躁,或不断换作业做."} options={['是','一般','否']}
                            ref={el => ref.current[27] = el}
                            setValue={(value) => {
                                setResponse(28, value)
                            }} value={response[28] || null}/>

        <SelectionQuestions readOnly={readOnly} key='29' question={"29. 对机械系统感兴趣,对多维或理论关系比如工程,天文,计算机系统,高中生的高等数学很好,擅长理解理论这种抽象的知识."} options={['是','一般','否']}
                            ref={el => ref.current[28] = el}
                            setValue={(value) => {
                                setResponse(29, value)
                            }} value={response[29] || null}/>

        <SelectionQuestions readOnly={readOnly} key='30' question={"30. 很容易看出同学中的小团体人际关系网,很会调解同学间的关系."} options={['是','一般','否']}
                            ref={el => ref.current[29] = el}
                            setValue={(value) => {
                                setResponse(30, value)
                            }} value={response[30] || null}/>

        <SelectionQuestions readOnly={readOnly} key='31' question={"31. 你的抽象思维很强,对文字,想法,概念,原则,价值观等抽象的东西感兴趣."} options={['是','一般','否']}
                            ref={el => ref.current[30] = el}
                            setValue={(value) => {
                                setResponse(31, value)
                            }} value={response[31] || null}/>

        <SelectionQuestions readOnly={readOnly} key='32' question={"32. 你是更倾向于在头脑中思考问题，还是需要在纸上书写思考?"} options={['思考','书写']}
                            ref={el => ref.current[31] = el}
                            setValue={(value) => {
                                setResponse(32, value)
                            }} value={response[32] || null}/>

        <SelectionQuestions readOnly={readOnly} key='33' question={"33. 在二维图中可以看出3D空间,动手能力强,喜欢制作,手工,烹饪."} options={['是','一般','否']}
                            ref={el => ref.current[32] = el}
                            setValue={(value) => {
                                setResponse(33, value)
                            }} value={response[33] || null}/>

        <SelectionQuestions readOnly={readOnly} key='34' question={"34. 能轻松记住房间中物体的位置和地图上的方向等,还能轻松地将信息转化为图表,图解,脑图."} options={['是','一般','否']}
                            ref={el => ref.current[33] = el}
                            setValue={(value) => {
                                setResponse(34, value)
                            }} value={response[34] || null}/>

        <SelectionQuestions readOnly={readOnly} key='35' question={"35. 你有关注细节的能力,能感知和记住小的变化,你能注意到不规律性."} options={['是','一般','否']}
                            ref={el => ref.current[34] = el}
                            setValue={(value) => {
                                setResponse(35, value)
                            }} value={response[35] || null}/>

        <SelectionQuestions readOnly={readOnly} key='36' question={"36. 你能轻松地回忆你眼睛看到过的内容,能快速轻松地记住来自书面文字的信息(不是听)."} options={['是','一般','否']}
                            ref={el => ref.current[35] = el}
                            setValue={(value) => {
                                setResponse(36, value)
                            }} value={response[36] || null}/>

        <SelectionQuestions readOnly={readOnly} key='37' question={"37. 你能轻松找到节拍,喜欢跳舞和其他运动,甚至被人认为好动,对吗."} options={['是','一般','否']}
                            ref={el => ref.current[36] = el}
                            setValue={(value) => {
                                setResponse(37, value)
                            }} value={response[37] || null}/>

        <SelectionQuestions readOnly={readOnly} key='38' question={"38. 你喜欢乐器喜欢唱歌吗?喜欢听书吗?"} options={['是','一般','否']}
                            ref={el => ref.current[37] = el}
                            setValue={(value) => {
                                setResponse(38, value)
                            }} value={response[38] || null}/>

        <SelectionQuestions readOnly={readOnly} key='39' question={"39. 你可以捕捉到声音或语调的微妙变化,英文口语特别是口音很容易,或者很容易学别人的腔调说话."} options={['是','一般','否']}
                            ref={el => ref.current[38] = el}
                            setValue={(value) => {
                                setResponse(39, value)
                            }} value={response[39] || null}/>

        <SelectionQuestions readOnly={readOnly} key='40' question={"40. 你有强烈的细微感官区分的能力,比如嗅觉味觉好,你能识别不同的味道,喜欢美食烹饪,摄影对你也很容易,绘画方面对色彩很敏感,能细分颜色的细节."} options={['是','一般','否']}
                            ref={el => ref.current[39] = el}
                            setValue={(value) => {
                                setResponse(40, value)
                            }} value={response[40] || null}/>

        <SelectionQuestions readOnly={readOnly} key='41' question={"41. 你在不刻意的情况下能快速轻松地记住数字和无关的杂事."} options={['是','一般','否']}
                            ref={el => ref.current[40] = el}
                            setValue={(value) => {
                                setResponse(41, value)
                            }} value={response[41] || null}/>

        <SelectionQuestions readOnly={readOnly} key='42' question={"42. 你可以很快而且很准确地读懂符号和文字,就像识别红绿灯一样简单,你能注意到一组一堆数字中的差异,你能比别人更快找到不同的数字或图形,错字."} options={['是','一般','否']}
                            ref={el => ref.current[41] = el}
                            setValue={(value) => {
                                setResponse(42, value)
                            }} value={response[42] || null}/>
    </div>
})

export default ForthSurvey;