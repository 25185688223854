import React from "react";
import BlankFillingQuestions from "../BlankFillingQuestions";
import './index.scss'
import SelectionQuestions from "../SelectionQuestions";
import MultiSelectQuestions from "../MultiSelectQuestions";
import ParagraphQuestions from "../ParagraphQuestions";

interface SeventhSurveyProps {
    response: any;
    setResponse: Function;
    readOnly: boolean;
}

//父母教育第一性原理级别测评
const SeventhSurvey = React.forwardRef<HTMLDivElement, SeventhSurveyProps>(({response, setResponse, readOnly}, ref: any) => {
    return <div className={"survey-question"}>
        <BlankFillingQuestions readOnly={readOnly} key='1' question={"1. 真实姓名/微信名"} ref={el => ref.current[0] = el}
                               setValue={(value) => {
                                   setResponse(1, value)
                               }} value={response[1] || null}/>
        <SelectionQuestions readOnly={readOnly} key='2' question={"2. 身份"} options={['母亲', '父亲']} ref={el => ref.current[1] = el}
                            setValue={(value) => {
                                setResponse(2, value)
                            }} value={response[2] || null}/>
        <BlankFillingQuestions readOnly={readOnly} key='3' question={"3. 手机"} ref={el => ref.current[2] = el} setValue={(value) => {
            setResponse(3, value)
        }} value={response[3] || null}/>
        <BlankFillingQuestions readOnly={readOnly} key='4' question={"4. 职业"} ref={el => ref.current[3] = el} setValue={(value) => {
            setResponse(4, value)
        }} value={response[4] || null}/>
        <SelectionQuestions readOnly={readOnly} key='5' question={"5. 最高学历（含目前在读）"}
                            options={['大学本/专科', '硕士研究生', '博士/博士后', '其他']}
                            ref={el => ref.current[4] = el} setValue={(value) => {
            setResponse(5, value)
        }} value={response[5] || null}/>
        <SelectionQuestions readOnly={readOnly} key='6' question={"6. 家庭年收入"}
                            options={['<20万', '<40万', '<60万', '<80万', '>100万']}
                            ref={el => ref.current[5] = el} setValue={(value) => {
            setResponse(6, value)
        }} value={response[6] || null}/>
        <MultiSelectQuestions readOnly={readOnly} key='7' question={"7. 情绪支持 (多选题)"}
                              options={['我总是密切关注孩子的情绪变化,每天与孩子对话沟通,在发现问题后帮助他们理解和处理情绪.', '我经常关注孩子的情绪变化,但并不是每天对话,我会每周与孩子沟通,或在孩子明显不舒服时与他们进行情绪相关的对话,并提供帮助.',
                                  '我通常只在孩子明显显示出情绪困扰时才会进行情绪相关的对话.', '我从不关注孩子的情绪变化,我不懂怎么与孩子进行情绪相关的对话.', '孩子会与父母表达情感,会与父母沟通.', '孩子不表达情感喜好,不与父母沟通.']}
                              ref={el => ref.current[6] = el} setValue={(value) => {
            setResponse(7, value)
        }} value={response[7] || null}/>
        <MultiSelectQuestions readOnly={readOnly} key='8' question={"8. 时间管理 (多选题)"}
                              options={['我在孩子很小时就已经培养好TA的作息时间,现在不用督促,自己会按时睡觉.', '我严格规定孩子的睡眠时间以确保他们有足够的休息时间.我们有明确的作息时间表,并且坚持执行.',
                                  '我们没有固定的作息时间表,我会催孩子睡觉,但孩子的睡眠和休息时间较为不稳定.', '我几乎不关注孩子是否有充足的睡眠时间和良好的睡眠质量.', '孩子有自觉做作业的习惯,我不用管.', '孩子要催才会完成作业,很头疼.', '孩子不让我管,我不知道是否完成作业.']}
                              ref={el => ref.current[7] = el} setValue={(value) => {
            setResponse(8, value)
        }} value={response[8] || null}/>
        <MultiSelectQuestions readOnly={readOnly} key='9' question={"9. 习惯管理 (多选题)"}
                              options={['我的孩子有很规律的习惯,包括:搞卫生,学习,阅读,运动,艺术等.', '父母单方面设定了家规,迫使孩子遵守,不是很能执行.',
                                  '我们没有养成习惯或家规,总要父母催.', '有时候根据孩子的表现给予奖励或惩罚.', '我们没有家规也没有设置奖罚机制,不罚也不奖励.', '我们有固定的家庭活动,如每周末一起运动或看电影或玩游戏,或其它项目.', '我们没有固定的家庭活动,想起就做.', '我们没有家庭活动.']}
                              ref={el => ref.current[8] = el} setValue={(value) => {
            setResponse(9, value)
        }} value={response[9] || null}/>
        <MultiSelectQuestions readOnly={readOnly} key='10' question={"10. 健康 (多选题)"}
                              options={['我很关注孩子的饮食习惯,并提供营养均衡的餐食.', '我们不太注意孩子的饮食是否均衡,有什么吃什么.',
                                  '我总是教育孩子养成良好的卫生习惯,如饭前,便后洗手,一天两次刷牙等.', '我不会在意孩子是否养成良好的卫生习惯,也不要求饭前,便后洗手,一天两次刷牙等.', '我总是确保孩子有足够的体育活动或艺术兴趣时间,因为对孩子很重要.', '我不关注孩子是否有足够的体育活动或艺术兴趣时间,因为对孩子不重要.',
                                  '我的孩子很阳光,我们亲子关系很好,什么都会沟通.', '孩子不主动跟我们沟通,简单问答.', '心理还好,但沟通不是很随意.', '孩子总是很敏感,不是很快乐.', '孩子不愿意跟我们沟通,不知道孩子的心理.']}
                              ref={el => ref.current[9] = el} setValue={(value) => {
            setResponse(10, value)
        }} value={response[10] || null}/>
        <MultiSelectQuestions readOnly={readOnly} key='11' question={"11. 兴趣培养 (多选题)"}
                              options={['孩子很小就开始参加各种兴趣活动,现在也在坚持（二年以上）自己喜欢的兴趣.', '我总是关注孩子的兴趣和需求,而且经常和他们一起讨论他们的感受.',
                                  '孩子有参加兴趣活动,我不知道孩子是否喜欢,但一直坚持.', '孩子有参加兴趣活动,但不是自己喜欢的,父母强迫.', '孩子没有兴趣爱好,我不知道孩子的兴趣和需求.']}
                              ref={el => ref.current[10] = el} setValue={(value) => {
            setResponse(11, value)
        }} value={response[11] || null}/>
        <MultiSelectQuestions readOnly={readOnly} key='12' question={"12. 学习力 (多选题)"}
                              options={['我主动定期了解孩子在学校的学习表现和进步,孩子会跟我愉悦讨论.', '我让孩子额外学习,补习.',
                                  '我对成绩不在意,我更在意孩子的身心健康和素质.', '我重视成绩,经常对学习成果进行监督,特别紧张.', '我教孩子如何高效地学习和管理时间.', '我鼓励孩子在学习中遇到困难时寻求帮助,并教会他们如何寻求帮助.',
                                  '我平时关注孩子的学习,能及时发现问题.', '我很少介入孩子的学习过程.','看到孩子成绩下降,我总是批评孩子,不问明原因.', '孩子不愿意跟我交流学习的情况.']}
                              ref={el => ref.current[11] = el} setValue={(value) => {
            setResponse(12, value)
        }} value={response[12] || null}/>

        <MultiSelectQuestions readOnly={readOnly} key='13' question={"13. 目标管理 (多选题)"}
                              options={['我关注孩子的长期兴趣和优势,我与孩子一起讨论未来的规划和目标.', '我自己给孩子做了未来发展的规划,孩子也愿意选择.','我做的规划孩子不同意,我们没有统一.',
                                  '我没有为孩子设定明确的长期目标.', '我很清楚孩子的兴趣和优势,也尊重孩子的选择.', '我不知道孩子的兴趣和优势.','我希望孩子听我的选择.']}
                              ref={el => ref.current[12] = el} setValue={(value) => {
            setResponse(13, value)
        }} value={response[13] || null}/>

        <MultiSelectQuestions readOnly={readOnly} key='14' question={"14. 沟通-价值观传递 (多选题)"}
                              options={['我跟孩子讨论各种问题,双方各抒己见时我会听孩子的想法,并共同寻求解决方案或真理.', '我跟孩子讨论时孩子不能有异议,因为我认为孩子应该与您观点一至,不愿意听孩子的想法.',
                                  '我经常跟孩子一起看电视,看书,看电影,并一起讨论.', '我几乎不跟孩子一起看电视,看书,看电影.', '我很少跟孩子讨论,也不知道孩子的思想.', '我根本不听孩子的想法.','孩子很欣赏我.',
                              '孩子不尊重我.','我很担心孩子的三观.']}
                              ref={el => ref.current[13] = el} setValue={(value) => {
            setResponse(14, value)
        }} value={response[14] || null}/>

        <MultiSelectQuestions readOnly={readOnly} key='15' question={"15. 亲密关系 (多选题)"}
                              options={['我跟配偶经常愉快聊天,我们相互欣赏,支持.', '我感受到被爱和肯定.',
                                  '我跟配偶没天可聊,但我们也不吵.', '我们只是维护家庭关系.', '我跟配偶无法聊天,三观不一致.', '我感受不到对方的爱和肯定.', '我们经常闹矛盾,吵架.', '我想离婚.', '我想离婚']}
                              ref={el => ref.current[14] = el} setValue={(value) => {
            setResponse(15, value)
        }} value={response[15] || null}/>

        <MultiSelectQuestions readOnly={readOnly} key='16' question={"16. 自我成长 (多选题)"}
                              options={['我认为自己有成长空间,不断学习让自己跟上时代.', '我不学习,也不改变,别人才应该学习改变.',
                                  '我想改变,但没有具体的方法.', '我经常看书,听讲座.', '我很久没看书没学习了.', '我偶尔学习新知,以适应教育孩子的需求,以身作则.',
                                  '我主要通过与他人交流获取新知识.', '我很少考虑自我成长或学习新事物.', '我有很多朋友可以相互倾诉','我不喜欢跟朋友谈心.','我坚信自己的改变和成长,会影响孩子的正向成长.','我觉得自己都懂,不需要学习.']}
                              ref={el => ref.current[15] = el} setValue={(value) => {
            setResponse(16, value)
        }} value={response[16] || null}/>

        <MultiSelectQuestions readOnly={readOnly} key='17' question={"17. 上大学最重要的目的是什么? (多选题)"}
                              options={['找工作/就业.', '拿到证书/学位.',
                                  '成长/人生的过程.', '学习知识.', '认识朋友,建立人脉.', '探索并尝试新事物(如俱乐部,课外活动等).',
                                  '为未来实现财务稳定或成功.', '形成独立思想.', '培养领导力','其他']}
                              ref={el => ref.current[16] = el} setValue={(value) => {
            setResponse(17, value)
        }} value={response[17] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='18' question={"18. 您做什么运动?一周几次?"} ref={el => ref.current[17] = el}
                               setValue={(value) => {
                                   setResponse(18, value)
                               }} value={response[18] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='19' question={"19. 您对人生的满足感是多少?0-10打分."}
                               ref={el => ref.current[19] = el}
                               setValue={(value) => {
                                   setResponse(19, value)
                               }} value={response[19] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='20' question={"20. 您带孩子参加过什么社交活动?列举出至少三个近期的活动."}
                               ref={el => ref.current[19] = el}
                               setValue={(value) => {
                                   setResponse(20, value)
                               }} value={response[20] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='21' question={"21. 在教导孩子处理人际冲突和解决问题时,给过哪些建议?至少三个."}
                               ref={el => ref.current[20] = el}
                               setValue={(value) => {
                                   setResponse(21, value)
                               }} value={response[21] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='22'
                               question={"22. 您如何确保自己对孩子在网络环境中的社交行为和安全有足够的了解和监管?您能分享一些您采取的具体措施吗?"}
                               ref={el => ref.current[21] = el}
                               setValue={(value) => {
                                   setResponse(22, value)
                               }} value={response[22] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='23' question={"23. 您是否为孩子分配适当的家务任务?如果是,孩子做什么家务?"}
                               ref={el => ref.current[22] = el}
                               setValue={(value) => {
                                   setResponse(23, value)
                               }} value={response[23] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='24' question={"24. 您是否让孩子参与家庭决策过程?如果是,请举三个例子."}
                               ref={el => ref.current[23] = el}
                               setValue={(value) => {
                                   setResponse(24, value)
                               }} value={response[24] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='25'
                               question={"25. 您是否教过孩子如何管理自己的金钱和物品?如果是,请列举教过的三个方法."}
                               ref={el => ref.current[24] = el}
                               setValue={(value) => {
                                   setResponse(25, value)
                               }} value={response[25] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='26' question={"26. 您是否告知孩子家庭的收支情况?孩子是否清楚家庭生活支出?"}
                               ref={el => ref.current[25] = el}
                               setValue={(value) => {
                                   setResponse(26, value)
                               }} value={response[26] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='27' question={"27. 您是否跟孩子讲工作上的得失?如果是,孩子有什么反应?"}
                               ref={el => ref.current[26] = el}
                               setValue={(value) => {
                                   setResponse(27, value)
                               }} value={response[27] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='28'
                               question={"28. 您是否有意识地向孩子传授解决问题和应对挑战的策略?如果有,您能否分享您引导孩子努力学习的三种具体技巧或方法?"}
                               ref={el => ref.current[27] = el}
                               setValue={(value) => {
                                   setResponse(28, value)
                               }} value={response[28] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='29' question={"29. 当孩子遇到困境时您是怎么处理的?请列举出至少一个例子?"}
                               ref={el => ref.current[28] = el}
                               setValue={(value) => {
                                   setResponse(29, value)
                               }} value={response[29] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='30' question={"30. 在培养孩子创新能力方面您有方法吗?如果有，请列举出至少三个例子?"}
                               ref={el => ref.current[29] = el}
                               setValue={(value) => {
                                   setResponse(30, value)
                               }} value={response[30] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='31'
                               question={"31. 您认为想像力重要吗?有具体做过什么来激发孩子的想像力吗?如果有请举例?"}
                               ref={el => ref.current[30] = el}
                               setValue={(value) => {
                                   setResponse(31, value)
                               }} value={response[31] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='32' question={"32. 您认为哪些自己的行为对孩子的道德观念形成产生了积极影响?"}
                               ref={el => ref.current[31] = el}
                               setValue={(value) => {
                                   setResponse(32, value)
                               }} value={response[32] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='33' question={"33. 您是否为孩子创造一个温馨,安全的家庭氛围? (0-10给自己打分)."}
                               ref={el => ref.current[32] = el}
                               setValue={(value) => {
                                   setResponse(33, value)
                               }} value={response[33] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='34' question={"34. 您和配偶在教育孩子的一致性上可以打多少分?(0-10给自己打分)"}
                               ref={el => ref.current[33] = el}
                               setValue={(value) => {
                                   setResponse(34, value)
                               }} value={response[34] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='35' question={"35. 孩子会跟您提出自己的意见和需求吗?提出来的需求有多少会被您接受?(请按百分比打分)"}
                               ref={el => ref.current[34] = el}
                               setValue={(value) => {
                                   setResponse(35, value)
                               }} value={response[35] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='36'
                               question={"36. 回想一下,最近一次跟孩子而产生的争论是为什么?您是如何处理的?"}
                               ref={el => ref.current[35] = el}
                               setValue={(value) => {
                                   setResponse(36, value)
                               }} value={response[36] || null}/>

    </div>
})

export default SeventhSurvey;