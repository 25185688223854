import React, {useEffect} from 'react';
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import { $generateHtmlFromNodes } from '@lexical/html';

// @ts-ignore
function OnChangePlugin({ onChange, setHtml}) {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        return editor.registerUpdateListener(({editorState}) => {
            onChange(editorState);
        });
    }, [editor, onChange]);

    useEffect(() => {
        return editor.registerUpdateListener(({editorState}) => {
            editor.update(() => {
                const htmlString = $generateHtmlFromNodes(editor);
                setHtml(htmlString)
            });
        });
    }, [editor, setHtml]);

    return null;
}

export default OnChangePlugin;