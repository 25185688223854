import React from 'react';
import "./index.scss"
import {AutoFocusPlugin} from '@lexical/react/LexicalAutoFocusPlugin';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {LexicalErrorBoundary} from '@lexical/react/LexicalErrorBoundary';
import ToolbarPlugin from "./plugins/ToolBarPlugin";
import OnChangePlugin from "./plugins/OnChangePlugin";
import InitContentPlugin from "./plugins/InitContentPlugin";

const placeholder = 'Enter some rich text...';

const theme = {
    code: 'editor-code',
    heading: {
        h1: 'editor-heading-h1',
        h2: 'editor-heading-h2',
        h3: 'editor-heading-h3',
        h4: 'editor-heading-h4',
        h5: 'editor-heading-h5',
    },
    image: 'editor-image',
    link: 'editor-link',
    list: {
        listitem: 'editor-listitem',
        nested: {
            listitem: 'editor-nested-listitem',
        },
        ol: 'editor-list-ol',
        ul: 'editor-list-ul',
    },
    ltr: 'ltr',
    paragraph: 'editor-paragraph',
    placeholder: 'editor-placeholder',
    quote: 'editor-quote',
    rtl: 'rtl',
    text: {
        bold: 'editor-text-bold',
        code: 'editor-text-code',
        hashtag: 'editor-text-hashtag',
        italic: 'editor-text-italic',
        overflowed: 'editor-text-overflowed',
        strikethrough: 'editor-text-strikethrough',
        underline: 'editor-text-underline',
        underlineStrikethrough: 'editor-text-underlineStrikethrough',
    },
};


interface RichTextProps {
    initText: string;
    onChange?: (editorState: any)=> void;
    setHtml?: any;
    editable?: boolean
}
const RichTextEditor:React.FC<RichTextProps> = ({initText, onChange, setHtml, editable}) => {
    const editorConfig = {
        namespace: 'yutong-rich-text',
        nodes: [],
        // Handling of errors during update
        onError(error: Error) {
            throw error;
        },
        editable: editable,
        theme: theme,
    };

    return (
        <LexicalComposer initialConfig={editorConfig}>
            <div className="editor-container">
                {editable && <ToolbarPlugin />}
                <div className="editor-inner">
                    <RichTextPlugin
                        contentEditable={
                            <ContentEditable
                                className="editor-input"
                                aria-placeholder={placeholder}
                                placeholder={
                                    <div className="editor-placeholder">{placeholder}</div>
                                }
                            />
                        }
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <HistoryPlugin />
                    <AutoFocusPlugin />
                    {onChange &&<OnChangePlugin onChange={onChange} setHtml={setHtml}/>}
                    <InitContentPlugin initText={initText}/>
                </div>
            </div>
        </LexicalComposer>
    );
}

export default RichTextEditor;