import React from "react";
import './index.scss'
import ParagraphQuestions from "../ParagraphQuestions";
import BlankFillingQuestions from "../BlankFillingQuestions";

interface SecondSurveyProps {
    response: any;
    setResponse: Function;
    readOnly: boolean;
}

//青少年自我探索
const SecondSurvey = React.forwardRef<HTMLDivElement, SecondSurveyProps>(({response, setResponse, readOnly}, ref: any) => {
    return <div className={"survey-question"}>
        <BlankFillingQuestions readOnly={readOnly} key='1' question={"1. 请用三个词描述你自己，并简要说明原因."}
                               ref={el => ref.current[0] = el}
                               setValue={(value) => {
                                   setResponse(1, value)
                               }} value={response[1] || null}/>

        <BlankFillingQuestions readOnly={readOnly} key='2'
                               question={"2. 如果只能用一句话介绍自己,这句话要很特别,还要让别人容易记住你,你会怎么说?"}
                               ref={el => ref.current[1] = el}
                               setValue={(value) => {
                                   setResponse(2, value)
                               }} value={response[2] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='3' question={"3. 你当前各科的成绩分别是多少?(列出科目和成绩)"}
                            ref={el => ref.current[2] = el}
                            setValue={(value) => {
                                setResponse(3, value)
                            }} value={response[3] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='4' question={"4. 在你所学的科目中,你最喜欢哪一个?为什么?"}
                            ref={el => ref.current[3] = el}
                            setValue={(value) => {
                                setResponse(4, value)
                            }} value={response[4] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='5' question={"5. 描述一个你在学习中面临过的主要挑战,以及你是如何应对的."}
                            ref={el => ref.current[4] = el}
                            setValue={(value) => {
                                setResponse(5, value)
                            }} value={response[5] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='6' question={"6. 在你的学习经历中,哪一件事让你感到最自豪?为什么?"}
                            ref={el => ref.current[5] = el}
                            setValue={(value) => {
                                setResponse(6, value)
                            }} value={response[6] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='7' question={"7. 未来你想在哪个学科上取得突破?有计划吗?你准备怎么做?"}
                            ref={el => ref.current[6] = el}
                            setValue={(value) => {
                                setResponse(7, value)
                            }} value={response[7] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='8' question={"8. 你是如何规划每天的学习时间的?有什么时间管理的技巧吗?"}
                            ref={el => ref.current[7] = el}
                            setValue={(value) => {
                                setResponse(8, value)
                            }} value={response[8] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='9' question={"9. 描述一个你最感兴趣的课外活动或爱好,并解释它对你的意义."}
                            ref={el => ref.current[8] = el}
                            setValue={(value) => {
                                setResponse(9, value)
                            }} value={response[9] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='10' question={"10. 你做了什么来发展这些兴趣?有没有需要克服的困难?"}
                            ref={el => ref.current[9] = el}
                            setValue={(value) => {
                                setResponse(10, value)
                            }} value={response[10] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='11' question={"11. 你想象中有趣的职业是什么?为什么?"}
                            ref={el => ref.current[10] = el}
                            setValue={(value) => {
                                setResponse(11, value)
                            }} value={response[11] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='12' question={"12. 如果有一天空闲时间,你通常会怎么度过?"}
                            ref={el => ref.current[11] = el}
                            setValue={(value) => {
                                setResponse(12, value)
                            }} value={response[12] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='13' question={"13. 你有创造力吗?是什么激发了你的创造力?你从哪里获得灵感?"}
                            ref={el => ref.current[12] = el}
                            setValue={(value) => {
                                setResponse(13, value)
                            }} value={response[13] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='14' question={"14. 当你感到压力或焦虑时,你会怎样处理这些情绪?"}
                            ref={el => ref.current[13] = el}
                            setValue={(value) => {
                                setResponse(14, value)
                            }} value={response[14] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='15' question={"15. 请描述一次失败的经历,以及你如何从中学习和成长."}
                            ref={el => ref.current[14] = el}
                            setValue={(value) => {
                                setResponse(15, value)
                            }} value={response[15] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='16' question={"16. 过去一年中你在哪些方面有显著提升?你是如何做到的?"}
                            ref={el => ref.current[15] = el}
                            setValue={(value) => {
                                setResponse(16, value)
                            }} value={response[16] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='17' question={"17. 描述一次你与他人合作的经历,你在其中学到了什么?"}
                            ref={el => ref.current[16] = el}
                            setValue={(value) => {
                                setResponse(17, value)
                            }} value={response[17] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='18' question={"18. 你如何处理与同学的冲突,请举一个具体的例子."}
                            ref={el => ref.current[17] = el}
                            setValue={(value) => {
                                setResponse(18, value)
                            }} value={response[18] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='19' question={"19. 描述一次你在团队或活动中展示出领导力的经历."}
                            ref={el => ref.current[18] = el}
                            setValue={(value) => {
                                setResponse(19, value)
                            }} value={response[19] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='20' question={"20. 你如何看待与朋友或同学的关系?你有几个要好的朋友?"}
                            ref={el => ref.current[19] = el}
                            setValue={(value) => {
                                setResponse(20, value)
                            }} value={response[20] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='21' question={"21. 你认为自己在沟通方面的优势和不足是什么?"}
                            ref={el => ref.current[20] = el}
                            setValue={(value) => {
                                setResponse(21, value)
                            }} value={response[21] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='22' question={"22. 描述一次你通过帮助他人而感到成就的经历."}
                            ref={el => ref.current[21] = el}
                            setValue={(value) => {
                                setResponse(22, value)
                            }} value={response[22] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='23' question={"23. 你希望在未来的五年里实现什么样的变化?为什么?"}
                            ref={el => ref.current[22] = el}
                            setValue={(value) => {
                                setResponse(23, value)
                            }} value={response[23] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='24' question={"24. 你认为什么是成功?你如何定义自己的成功?"}
                            ref={el => ref.current[23] = el}
                            setValue={(value) => {
                                setResponse(24, value)
                            }} value={response[24] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='25' question={"25. 在未来,你有什么想实现的愿望或目标?"}
                            ref={el => ref.current[24] = el}
                            setValue={(value) => {
                                setResponse(25, value)
                            }} value={response[25] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='26' question={"26. 描述你理想中的生活方式和状态."}
                            ref={el => ref.current[25] = el}
                            setValue={(value) => {
                                setResponse(26, value)
                            }} value={response[26] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='27' question={"27. 你有没有什么独特的生活经历,如旅游或居住在不同的城市,国家或文化?"}
                            ref={el => ref.current[26] = el}
                            setValue={(value) => {
                                setResponse(27, value)
                            }} value={response[27] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='28' question={"28. 你对不同文化的态度是什么?有什么特别的见解?"}
                            ref={el => ref.current[27] = el}
                            setValue={(value) => {
                                setResponse(28, value)
                            }} value={response[28] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='29' question={"29. 在你的家人中，谁对你的成长影响最大?为什么?"}
                            ref={el => ref.current[28] = el}
                            setValue={(value) => {
                                setResponse(29, value)
                            }} value={response[29] || null}/>

        <ParagraphQuestions readOnly={readOnly} key='30' question={"30. 日常生活,你有哪些好习惯?"}
                            ref={el => ref.current[29] = el}
                            setValue={(value) => {
                                setResponse(30, value)
                            }} value={response[30] || null}/>
    </div>
})

export default SecondSurvey;