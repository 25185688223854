//@ts-nocheck
import React from 'react';
import {useParams} from "react-router-dom";
import {useRequest} from "ahooks";
import {fetchSurveyReports, getSurveyGroupByUUID} from "../../../../requests";
import "../index.scss"
import RichTextEditor from "../../../RichTextEditor";
import {SURVEY_TYPE_MAP} from "../../../../constants";
import {SixthSurveyReport} from "../../AdminSurveyReport";
import reportImage from '../../../../assets/report.png';

const ReportPdf = () => {
    const params = useParams()
    const {data: reports} = useRequest(() => fetchSurveyReports(params.uuid))
    const {data: sg} = useRequest(() => getSurveyGroupByUUID(params.uuid))
    return <div id="pdf-content" className={"pdf-content all-report"}>
        {/* First Page: Title */}
        <div className='page'>
            <h1>个性化AI定制规划报告</h1>
        </div>
        <div className='page image-page'>
            <div className={'student-name-text'}>{sg?.student_name}</div>
            <img src={reportImage} alt="Report Image" className={'report-image'}/>
        </div>

        {reports && reports.map((u, index) => {
            if(u.survey_type === 6 || u.survey_type === 8) {
                return <div className={'page'}><div className={'title'}>{`${SURVEY_TYPE_MAP[u.survey_type]?.name}分析结果`}</div>
                    <SixthSurveyReport report={u['gpt_result']}/>  </div>
            } else {
                return <div className={'page'}>
                    <div className={'title'}>{`${SURVEY_TYPE_MAP[u.survey_type]?.name}分析结果`}</div>
                    <div key={index}><RichTextEditor initText={u['gpt_result']} editable={false}/></div>
                </div>
            }})
        }
    </div>
}

export default ReportPdf;