import React, {useEffect, useState} from 'react';
import "./index.scss"
import {ReactComponent as LoginBackground} from '../../assets/login-background.svg';
import {Button, Checkbox, Input, message} from "antd";
import {adminLogin, updateReport} from "../../requests";
import {useNavigate} from "react-router-dom";



const Login = () => {
    const navigate = useNavigate();
    const [rememberMe, setRememberMe] = useState(() => {
        return localStorage.getItem('rememberMe') === 'true';
    });
    const [username, setUsername] = useState(() => {
        if(rememberMe) {
            return localStorage.getItem('username') || ''
        } else {
            return ''
        }
    });
    const [password, setPassword] = useState('');
    useEffect(()=>{
        localStorage.setItem('rememberMe', JSON.stringify(rememberMe));
        if(!rememberMe) {
            localStorage.removeItem('username')
        }
    },[rememberMe])

    const handleLogin = async () => {
        if(rememberMe) {
            localStorage.setItem('username', username);
        }
        const result = await adminLogin(username, password)
        if (!result.error) {
            localStorage.setItem('token', result.token);
            message.success("Login successfully.")
            navigate('/admin', {replace: true});
        }
    };

    return (
        <div className="login">
        <LoginBackground className="login-background"/>
            <div className={"login-content"}>
                <div className={'login-text'}>欢迎</div>
                <div className="input-field input-field-first">
                    <Input
                        placeholder="User name"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className="input-field">
                    <Input.Password
                        placeholder="Password"
                        value={password}
                        // iconRender={(visible) => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className="options">
                    {/*@ts-ignore*/}
                    <Checkbox className={'checkbox'} checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)}>
                        Remember me
                    </Checkbox>
                    <a className="forgot-password">Forgot Password?</a>
                </div>
                <Button type="primary" onClick={handleLogin} className="login-button">
                    登陆
                </Button>
            </div>

        </div>)
}

export default Login;