import React, { useState} from 'react';
import './index.scss';
import {useRequest} from "ahooks";
import {
    fetchSurveyReport, isTokenValid, regenerateReport,
    updateReport,
} from "../../../requests";
import {useParams} from "react-router-dom";
import RichTextEditor from "../../RichTextEditor";
import {SURVEY_TYPE_MAP} from "../../../constants";
import {ReactComponent as SaveReportButton} from '../../../assets/save-report-icon.svg';
import {ReactComponent as SaveReportButtonHovered} from '../../../assets/save-report-icon-hover.svg';
import {ReactComponent as RegenerateButtonHovered} from '../../../assets/regenerate-icon-hover.svg';
import {ReactComponent as RegenerateButton} from '../../../assets/regenerate-icon.svg';
import {message, Spin} from "antd";
import { Column } from '@ant-design/plots';


// @ts-ignore
export const ReportChart = ({data}) => {
    const config = {
        data,
        xField: 'answer',
        yField: 'count',
        barWidthRatio: 0.3,
        style: {
            // @ts-ignore
            fill: ({ answer }) => {
                switch (answer) {
                    case 'A':
                        return '#1E90FF'
                    case 'B':
                        return '#4682B4'
                    case 'C':
                        return '#5F9EA0'
                    case 'D':
                        return '#87CEEB'
                    case 'E':
                        return '#4169E1'
                    default:
                        return '#1E90FF'
                }
            },
        },
        label: {
            // @ts-ignore
            text: (originData) => {
                return `${originData.answer}:${originData.count}`;
            },
            offset: 10,
        },
        legend: true,
    };
    return <Column {...config} className={'chart'}/>;
};

// @ts-ignore
export const SixthSurveyReport = ({report}) => {
    const reportObj = JSON.parse(report)
    const countLetters = (item: any) => {
        const letterCount = {A: 0, B: 0, C: 0, D: 0, E: 0};

        // Iterate through the object's values and count occurrences of A, B, C, D, and E
        for (const key in item) {
            const value = item[key];
            if (letterCount.hasOwnProperty(value)) {
                // @ts-ignore
                letterCount[value]++;
            }
        }
        return Object.keys(letterCount).map(key => ({
            answer: key,
            // @ts-ignore
            count: letterCount[key]
        }));
    }

    const chartData = countLetters(reportObj) || []
    return (
    <div className={'chart-container'}>
        <div className={'chart-item'}><ReportChart data={chartData}/></div>
        <div className={'chart-table'}>
            <table className={'table'}>
                <thead>
                <tr>
                    {chartData.map((item) => (
                        <th key={item.answer}>{item.answer}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                <tr>
                    {chartData.map((item) => (
                        <td key={item.count}>
                            {item.count}
                        </td>
                    ))}
                </tr>
                </tbody>
            </table>
            <div className={'sub-text'}>A 肯定言语 WORDS OF AFFIRMATION</div>
            <div className={'sub-text'}>B 精心陪伴 QUALITY TIME</div>
            <div className={'sub-text'}>C 接受礼物 RECEIVING OF GIFT</div>
            <div className={'sub-text'}>D 服务帮助 ACT OF SERVICES</div>
            <div className={'sub-text'}>E 身体触摸 PHYSICAL TOUCH</div>
        </div>
    </div>)
}
const AdminSurveyReport = () => {
    const {data: tokenValidation} = useRequest(isTokenValid)
    const params = useParams()
    // @ts-ignore
    const {data: report, run: refetch, loading: dataLoading} = useRequest(() => fetchSurveyReport(params.uuid))

    const [editorState, setEditorState] = useState();
    const [html, setHtml] = useState()
    const [saveButtonHovered, setSaveButtonHovered] = useState(false);
    const [regenerateButtonHovered, setRegenerateButtonHovered] = useState(false);
    const [regenerateLoading, setRegenerateLoading] = useState(false);
    function onChange(editorState: any) {
        setEditorState(editorState);
    }

    const saveReport = async () => {
        // @ts-ignore
        const result = await updateReport(params.uuid, html)
        if (!result.error) {
            message.success("Successfully saved the report.")
        }
    }

    const regenerateReportCallback = async () => {
        setRegenerateLoading(true)
        // @ts-ignore
        const result = await regenerateReport(params.uuid)
        if(!result.error) {
            message.success("Successfully saved the report.")
        }
        setRegenerateLoading(false)
        refetch()
    }


    return (
        <div className={"out-container "}>
            <div className={'list-container admin-list-container'}>
                {(dataLoading || regenerateLoading) && <Spin fullscreen size={"large"}/>}
                {/*@ts-ignore*/}
                {!(dataLoading) && report && <div className={'title'}>{`${SURVEY_TYPE_MAP[report.survey_type]?.name}分析结果`}</div>}
                {!(dataLoading) && report && (report.survey_type === 6 || report.survey_type === 8) && <SixthSurveyReport report={report['gpt_result']} />}
                {!(dataLoading || regenerateLoading) && report && report.survey_type !== 6 && report.survey_type !== 8 &&
                    <RichTextEditor editable={true} initText={report['gpt_result']} onChange={onChange}
                                    setHtml={setHtml}/>}
                {!(dataLoading || regenerateLoading) && report && report.survey_type !== 8 && report.survey_type !== 6 && <div className={'button-wrapper'}>
                    <div onClick={regenerateReportCallback} className={'save-button'}
                         onMouseEnter={() => setRegenerateButtonHovered(true)}
                         onMouseLeave={() => setRegenerateButtonHovered(false)}>
                        {regenerateButtonHovered ?
                            <RegenerateButtonHovered/> : <RegenerateButton/>}
                    </div>
                    <div onClick={saveReport} className={'save-button'}
                         onMouseEnter={() => setSaveButtonHovered(true)}
                         onMouseLeave={() => setSaveButtonHovered(false)}>
                    {saveButtonHovered ?
                            <SaveReportButtonHovered/> : <SaveReportButton/>}
                    </div>
                </div>}

            </div>
        </div>
    );
};

export default AdminSurveyReport;