import React from "react";
import './index.scss'
import BlankFillingQuestions from "../BlankFillingQuestions";
import SelectionQuestions from "../SelectionQuestions";

interface ThirdSurveyProps {
    response: any;
    setResponse: Function;
    readOnly: boolean;
}

//青少年批判性思维能力测评
const ThirdSurvey = React.forwardRef<HTMLDivElement, ThirdSurveyProps>(({response, setResponse, readOnly}, ref: any) => {
    return <div className={"survey-question"}>
        <BlankFillingQuestions readOnly={readOnly} key='1' question={"1. 姓名"}
                               ref={el => ref.current[0] = el}
                               setValue={(value) => {
                                   setResponse(1, value)
                               }} value={response[1] || null}/>

        <SelectionQuestions readOnly={readOnly} key='2' question={"2. 性别"} options={['男', '女']} ref={el => ref.current[1] = el}
                            setValue={(value) => {
                                setResponse(2, value)
                            }} value={response[2] || null}/>

        <BlankFillingQuestions readOnly={readOnly} key='3' question={"3. 年龄"}
                               ref={el => ref.current[2] = el}
                               setValue={(value) => {
                                   setResponse(3, value)
                               }} value={response[3] || null}/>

        <SelectionQuestions readOnly={readOnly} key='4' question={"4. 太阳从东边升起."} options={['事实', '观点']}
                            ref={el => ref.current[3] = el}
                            setValue={(value) => {
                                setResponse(4, value)
                            }} value={response[4] || null}/>

        <SelectionQuestions readOnly={readOnly} key='5' question={"5. 中国是世界上人口最多的国家."} options={['事实', '观点']}
                            ref={el => ref.current[4] = el}
                            setValue={(value) => {
                                setResponse(5, value)
                            }} value={response[5] || null}/>

        <SelectionQuestions readOnly={readOnly} key='6' question={"6. 巧克力是世界上最好的糖果."} options={['事实', '观点']}
                            ref={el => ref.current[5] = el}
                            setValue={(value) => {
                                setResponse(6, value)
                            }} value={response[6] || null}/>

        <SelectionQuestions readOnly={readOnly} key='7' question={"7. 打电子游戏会导致成绩下降."} options={['事实', '观点']}
                            ref={el => ref.current[6] = el}
                            setValue={(value) => {
                                setResponse(7, value)
                            }} value={response[7] || null}/>

        <SelectionQuestions readOnly={readOnly} key='8' question={"8. 地球是平的."} options={['事实', '观点']}
                            ref={el => ref.current[7] = el}
                            setValue={(value) => {
                                setResponse(8, value)
                            }} value={response[8] || null}/>

        <SelectionQuestions readOnly={readOnly} key='9' question={"9. 人类是通过呼吸吸收氧气并排出二氧化碳的."} options={['事实', '观点']}
                            ref={el => ref.current[8] = el}
                            setValue={(value) => {
                                setResponse(9, value)
                            }} value={response[9] || null}/>

        <SelectionQuestions readOnly={readOnly} key='10' question={"10. 水是透明的."} options={['事实', '观点']}
                            ref={el => ref.current[9] = el}
                            setValue={(value) => {
                                setResponse(10, value)
                            }} value={response[10] || null}/>

        <SelectionQuestions readOnly={readOnly} key='11' question={"11. 现在的青少年都沉迷网络."} options={['事实', '观点']}
                            ref={el => ref.current[10] = el}
                            setValue={(value) => {
                                setResponse(11, value)
                            }} value={response[11] || null}/>

        <SelectionQuestions readOnly={readOnly} key='12' question={"12. 猫比狗好,因为猫更独立."} options={['事实', '观点']}
                            ref={el => ref.current[11] = el}
                            setValue={(value) => {
                                setResponse(12, value)
                            }} value={response[12] || null}/>

        <SelectionQuestions readOnly={readOnly} key='13' question={"13. 人工智能将会取代所有的工作."} options={['事实', '观点']}
                            ref={el => ref.current[12] = el}
                            setValue={(value) => {
                                setResponse(13, value)
                            }} value={response[13] || null}/>

        <SelectionQuestions readOnly={readOnly} key='14' question={"14. 一种叫做COVID的病毒引发了2020年的全球大流行."}
                            options={['事实', '观点']} ref={el => ref.current[13] = el}
                            setValue={(value) => {
                                setResponse(14, value)
                            }} value={response[14] || null}/>

        <SelectionQuestions readOnly={readOnly} key='15' question={"15. 学生应该在完成所有家庭作业后才能玩电子游戏."}
                            options={['事实', '观点']} ref={el => ref.current[14] = el}
                            setValue={(value) => {
                                setResponse(15, value)
                            }} value={response[15] || null}/>

        <SelectionQuestions readOnly={readOnly} key='16' question={"16. 过多的电子设备使用时间会对学生的学习产生负面影响."}
                            options={['事实', '观点']} ref={el => ref.current[15] = el}
                            setValue={(value) => {
                                setResponse(16, value)
                            }} value={response[16] || null}/>

        <SelectionQuestions readOnly={readOnly} key='17' question={"17. 睡眠对于学生的健康和学习是非常重要的."} options={['事实', '观点']}
                            ref={el => ref.current[16] = el}
                            setValue={(value) => {
                                setResponse(17, value)
                            }} value={response[17] || null}/>

        <SelectionQuestions readOnly={readOnly} key='18' question={"18. 体育活动对学生的大脑很有帮助,可以提高记忆力."}
                            options={['事实', '观点']} ref={el => ref.current[17] = el}
                            setValue={(value) => {
                                setResponse(18, value)
                            }} value={response[18] || null}/>

        <SelectionQuestions readOnly={readOnly} key='19' question={"19. 学生们在学校应该只专注于学习,而不是社交活动."}
                            options={['事实', '观点']} ref={el => ref.current[18] = el}
                            setValue={(value) => {
                                setResponse(19, value)
                            }} value={response[19] || null}/>

        <SelectionQuestions readOnly={readOnly} key='20' question={"20. 研究表明,定期进行家庭活动可以提高家庭的凝聚力."}
                            options={['事实', '观点']} ref={el => ref.current[19] = el}
                            setValue={(value) => {
                                setResponse(20, value)
                            }} value={response[20] || null}/>

        <SelectionQuestions readOnly={readOnly} key='21' question={"21. 冲突可以通过沟通和谅解来解决."} options={['事实', '观点']}
                            ref={el => ref.current[20] = el}
                            setValue={(value) => {
                                setResponse(21, value)
                            }} value={response[21] || null}/>

        <SelectionQuestions readOnly={readOnly} key='22' question={"22. 父母的肯定和支持可以帮助学生建立自信."} options={['事实', '观点']}
                            ref={el => ref.current[21] = el}
                            setValue={(value) => {
                                setResponse(22, value)
                            }} value={response[22] || null}/>

        <SelectionQuestions readOnly={readOnly} key='23' question={"23. 不喜欢的科目就不需要学习."} options={['事实', '观点']}
                            ref={el => ref.current[22] = el}
                            setValue={(value) => {
                                setResponse(23, value)
                            }} value={response[23] || null}/>

        <SelectionQuestions readOnly={readOnly} key='24' question={"24. 阅读可以帮助我们培养批判性思维."} options={['事实', '观点']}
                            ref={el => ref.current[23] = el}
                            setValue={(value) => {
                                setResponse(24, value)
                            }} value={response[24] || null}/>

        <SelectionQuestions readOnly={readOnly} key='25' question={"25. 在学习过程中遇到困难是正常的,我们可以从中学到如何解决问题."}
                            options={['事实', '观点']} ref={el => ref.current[24] = el}
                            setValue={(value) => {
                                setResponse(25, value)
                            }} value={response[25] || null}/>

        <SelectionQuestions readOnly={readOnly} key='26'
                            question={"26. 当你在看新闻或者阅读文章的时候,你会主动去区分哪些是事实,哪些是作者的观点吗?"}
                            options={['经常', '有时', '从不']} ref={el => ref.current[25] = el}
                            setValue={(value) => {
                                setResponse(26, value)
                            }} value={response[26] || null}/>

        <SelectionQuestions readOnly={readOnly} key='27'
                            question={"27. 你会主动去寻找或者验证事实吗?"}
                            options={['经常', '有时', '从不']} ref={el => ref.current[26] = el}
                            setValue={(value) => {
                                setResponse(27, value)
                            }} value={response[27] || null}/>

        <SelectionQuestions readOnly={readOnly} key='28'
                            question={"28. 当你在看电影或者电视剧的时候,你会质疑剧情吗?"}
                            options={['经常', '有时', '从不']} ref={el => ref.current[27] = el}
                            setValue={(value) => {
                                setResponse(28, value)
                            }} value={response[28] || null}/>

        <SelectionQuestions readOnly={readOnly} key='29'
                            question={"29. 当你在使用网络搜索时,你会对比几个网站然后做选择吗?"}
                            options={['经常', '有时', '从不']} ref={el => ref.current[28] = el}
                            setValue={(value) => {
                                setResponse(29, value)
                            }} value={response[29] || null}/>

        <SelectionQuestions readOnly={readOnly} key='30'
                            question={"30. 你会事先思考解决问题的步骤和策略吗?"}
                            options={['经常', '有时', '从不']} ref={el => ref.current[29] = el}
                            setValue={(value) => {
                                setResponse(30, value)
                            }} value={response[30] || null}/>

        <SelectionQuestions readOnly={readOnly} key='31'
                            question={"31. 在跟人有争执时,你会跟对方统一你们对事物的定义吗?也就是确定你们在讨论同一件事?"}
                            options={['经常', '有时', '从不']} ref={el => ref.current[30] = el}
                            setValue={(value) => {
                                setResponse(31, value)
                            }} value={response[31] || null}/>

        <SelectionQuestions readOnly={readOnly} key='32'
                            question={"32. 面复杂的问题或任务时,你会尝试把它分解成更小,更易于处理的部分吗?"}
                            options={['经常', '有时', '从不']} ref={el => ref.current[31] = el}
                            setValue={(value) => {
                                setResponse(32, value)
                            }} value={response[32] || null}/>

        <SelectionQuestions readOnly={readOnly} key='33'
                            question={"33. 当你听到一个论点时,会尝试理解其背后的逻辑和理由?"}
                            options={['经常', '有时', '从不']} ref={el => ref.current[32] = el}
                            setValue={(value) => {
                                setResponse(33, value)
                            }} value={response[33] || null}/>

        <SelectionQuestions readOnly={readOnly} key='34'
                            question={"34. 当你提出观点或论断时,会提供相应的证据或理由吗?"}
                            options={['经常', '有时', '从不']} ref={el => ref.current[33] = el}
                            setValue={(value) => {
                                setResponse(34, value)
                            }} value={response[34] || null}/>

        <SelectionQuestions readOnly={readOnly} key='35'
                            question={"35. 你经常发现并质疑别人论断中的矛盾或不合逻辑之处."}
                            options={['经常', '有时', '从不']} ref={el => ref.current[34] = el}
                            setValue={(value) => {
                                setResponse(35, value)
                            }} value={response[35] || null}/>

        <SelectionQuestions readOnly={readOnly} key='36'
                            question={"36. 当你看到一则广告时,你是否会自己判断其中的事实和推销员的观点?"}
                            options={['经常', '有时', '从不']} ref={el => ref.current[35] = el}
                            setValue={(value) => {
                                setResponse(36, value)
                            }} value={response[36] || null}/>

        <SelectionQuestions readOnly={readOnly} key='37'
                            question={"37. 当你在看书时,你是否会尝试去思考作者的目的?"}
                            options={['经常', '有时', '从不']} ref={el => ref.current[36] = el}
                            setValue={(value) => {
                                setResponse(37, value)
                            }} value={response[37] || null}/>

        <SelectionQuestions readOnly={readOnly} key='38'
                            question={"38. 当你在阅读社交媒体视频或文章时,你是否会去分辨哪些是事实,哪些是个人观点?"}
                            options={['经常', '有时', '从不']} ref={el => ref.current[37] = el}
                            setValue={(value) => {
                                setResponse(38, value)
                            }} value={response[38] || null}/>

        <SelectionQuestions readOnly={readOnly} key='39'
                            question={"39. 当你完成一个任务或者做出一个决策后,你会花时间去反思结果和过程吗?"}
                            options={['经常', '有时', '从不']} ref={el => ref.current[38] = el}
                            setValue={(value) => {
                                setResponse(39, value)
                            }} value={response[39] || null}/>

        <SelectionQuestions readOnly={readOnly} key='40'
                            question={"40. 你会跟其他同学比较物质水平吗."}
                            options={['经常', '有时', '从不']} ref={el => ref.current[39] = el}
                            setValue={(value) => {
                                setResponse(40, value)
                            }} value={response[40] || null}/>

        <SelectionQuestions readOnly={readOnly} key='41'
                            question={"41. 你在学习新知识时遇到困难，你会如何处理."}
                            options={['寻求其他人的帮助', '列出所有可能的解决方案,比如寻找更多资源,然后选择一个最有效的.', '忽略它,继续学习其他部分.', '放弃学习,认为太难了.']}
                            ref={el => ref.current[40] = el}
                            setValue={(value) => {
                                setResponse(41, value)
                            }} value={response[41] || null}/>

        <SelectionQuestions readOnly={readOnly} key='42'
                            question={"42. 你会思考老师说的道理是否都合理吗?"}
                            options={['经常质疑,分析其论据,评估其合理性.', '有时会质疑.', '不敢质疑.', '直接接受,老师不会错.']}
                            ref={el => ref.current[41] = el}
                            setValue={(value) => {
                                setResponse(42, value)
                            }} value={response[42] || null}/>

        <SelectionQuestions readOnly={readOnly} key='43'
                            question={"43. 当你在团队项目中遇到团队成员意见不一致的情况,你会怎么做?"}
                            options={['让大家都表述自己的观点,然后共同决定最好的方案.', '跟随大多数人的意见.', '无法作出决定,因为不想引起冲突.', '忽略不同的意见,坚持自己的看法.']}
                            ref={el => ref.current[42] = el}
                            setValue={(value) => {
                                setResponse(43, value)
                            }} value={response[43] || null}/>

        <SelectionQuestions readOnly={readOnly} key='44'
                            question={"44. 做作业碰到难题,你会怎么做?"}
                            options={['自己尝试使用不同的方法,直到找到解决问题的办法.', '寻找别人帮助.', '责备老师.', '躲避,不去面对问题.']}
                            ref={el => ref.current[43] = el}
                            setValue={(value) => {
                                setResponse(44, value)
                            }} value={response[44] || null}/>

        <SelectionQuestions readOnly={readOnly} key='45'
                            question={"45. 如果有人说美国不安全时,你不知道是否安全,你会?"}
                            options={['让对方提供证明.', '自己找证明.', '不思考.']}
                            ref={el => ref.current[44] = el}
                            setValue={(value) => {
                                setResponse(45, value)
                            }} value={response[45] || null}/>

        <SelectionQuestions readOnly={readOnly} key='46'
                            question={"46. 当你出错时,你会如何处理?"}
                            options={['反思错误,学习如何避免在将来重蹈覆辙.', '仅仅是感到沮丧,但没有实质性的行动.', '害怕面对错误,怕别人笑话.', '归咎于外部因素(别人的问题).']}
                            ref={el => ref.current[45] = el}
                            setValue={(value) => {
                                setResponse(46, value)
                            }} value={response[46] || null}/>

        <SelectionQuestions readOnly={readOnly} key='47'
                            question={"47. 当你完成一次考试后,你会做什么?"}
                            options={['无论成绩如何,都会反思自己的复习策略和考试策略.', '只有在成绩不好的时候才会反思.', '不做任何事.']}
                            ref={el => ref.current[46] = el}
                            setValue={(value) => {
                                setResponse(47, value)
                            }} value={response[47] || null}/>

        <SelectionQuestions readOnly={readOnly} key='48'
                            question={"48. 当你在生活中遇到问题,比如自行车胎坏了,你会怎么做?"}
                            options={['尝试自己解决问题,比如通过在线教程学习如何修理自行车.', '直接买一辆新的自行车.', '寻找别人帮忙修理.','放弃骑自行车.']}
                            ref={el => ref.current[47] = el}
                            setValue={(value) => {
                                setResponse(48, value)
                            }} value={response[48] || null}/>

        <SelectionQuestions readOnly={readOnly} key='49'
                            question={"49. 当你与朋友有意见冲突时,你会:"}
                            options={['听取别人的解释,尝试理解他们.', '立即让步,以免争论.', '坚持你自己的看法,不考虑他们的意见.','你的观点立即改变,以符合大多数人的观点.']}
                            ref={el => ref.current[48] = el}
                            setValue={(value) => {
                                setResponse(49, value)
                            }} value={response[49] || null}/>

        <SelectionQuestions readOnly={readOnly} key='50'
                            question={"50. 当你在网络上看到一个观点与你所知道的不同时,你会怎么处理?"}
                            options={['尝试找到更多的信息来证实你的观点或理解新的观点.', '忽略他人,坚信自己.', '开始质疑你自己的认知.']}
                            ref={el => ref.current[49] = el}
                            setValue={(value) => {
                                setResponse(50, value)
                            }} value={response[50] || null}/>

        <SelectionQuestions readOnly={readOnly} key='51'
                            question={"51. 当你的计划未能达到预期的效果时,你会怎样做?"}
                            options={['找出其中的错误并尝试改正.', '忽视结果,希望下次运气更好.', '责怪他人未能帮助你成功.']}
                            ref={el => ref.current[50] = el}
                            setValue={(value) => {
                                setResponse(51, value)
                            }} value={response[51] || null}/>

        <SelectionQuestions readOnly={readOnly} key='52'
                            question={"52. 在每天结束时,你会花时间回顾自己一天的表现吗?"}
                            options={['是的,我会反思我的决策和行为.', '只在发生了重大事件时才这样做.', '不,我更愿意专注于未来.', '不,我觉得这是浪费时间.']}
                            ref={el => ref.current[51] = el}
                            setValue={(value) => {
                                setResponse(52, value)
                            }} value={response[52] || null}/>

        <SelectionQuestions readOnly={readOnly} key='53'
                            question={"53. 当你成功完成一个任务时,你会:"}
                            options={['仔细思考并理解为什么你的策略成功.', '感谢那些帮助过你的人.', '简单地欢呼,然后开始下一个任务.', '将成功归功于好运.']}
                            ref={el => ref.current[52] = el}
                            setValue={(value) => {
                                setResponse(53, value)
                            }} value={response[53] || null}/>

        <SelectionQuestions readOnly={readOnly} key='54'
                            question={"54. 学生只应该学习,不应该做家务."}
                            options={['家务是生活的一部分,通过参与家务,可以学习到生活技能.', '学习是学生的主要任务,应该投入全部精力.', '知道应该但不喜欢做家务.']}
                            ref={el => ref.current[53] = el}
                            setValue={(value) => {
                                setResponse(54, value)
                            }} value={response[54] || null}/>

        <SelectionQuestions readOnly={readOnly} key='55'
                            question={"55. 父母是否应该拿学生跟别人比较."}
                            options={['每个学生都是独特的,不能进行简单的比较.', '合理的比较可以激发学生的进步欲望,理解自己在哪里需要改进.', '比较可能会伤害学生的自尊心,引发学生之间的竞争和敌意.']}
                            ref={el => ref.current[54] = el}
                            setValue={(value) => {
                                setResponse(55, value)
                            }} value={response[55] || null}/>
    </div>
})

export default ThirdSurvey;