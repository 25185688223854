import React, {useEffect} from 'react';
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import { $generateNodesFromDOM } from '@lexical/html';
import {$createParagraphNode, $createTextNode, $getRoot} from "lexical";

// @ts-ignore
function InitContentPlugin({ initText}) {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        editor.update(() => {
            const root = $getRoot();
            root.clear()
            if(initText?.includes("<p")) {

                // In the browser you can use the native DOMParser API to parse the HTML string.
                const parser = new DOMParser();
                const dom = parser.parseFromString(initText, "text/html");

                // Once you have the DOM instance it's easy to generate LexicalNodes.
                const nodes = $generateNodesFromDOM(editor, dom);

                nodes.forEach((node) => root.append(node))

            } else {
                const p = $createParagraphNode();
                p.append($createTextNode(initText || ''));
                root.append(p);
            }
        });
    }, [editor,initText]);

    return null;
}

export default InitContentPlugin;