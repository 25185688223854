import React from "react";
import './index.scss'
import SelectionQuestions from "../SelectionQuestions";

interface FirstSurveyProps {
    response: any;
    setResponse: Function;
    readOnly: boolean;
}

//青少年自驱力检测表
const FirstSurvey = React.forwardRef<HTMLDivElement, FirstSurveyProps>(({response, setResponse, readOnly}, ref: any) => {
    return <div className={"survey-question"}>
        <div className={'sub-instruction'}>请根据以下陈述，选择最符合您情况的选项。评分标准为：{'\n'}
            5分 - 非常符合{'\n'}
            4分 - 比较符合{'\n'}
            3分 - 一般{'\n'}
            2分 - 不太符合{'\n'}
            1分 - 完全不符合{'\n'}
        </div>
        <SelectionQuestions key='1' question={"1. 我能自觉独立完成作业."} options={['5', '4', '3', '2', '1']}
                            ref={el => ref.current[0] = el}
                            setValue={(value) => {
                                setResponse(1, value)
                            }} value={response[1] || null} readOnly={readOnly}/>
        <SelectionQuestions key='2' question={"2. 即使没有考试或作业,我也会主动学习和阅读."}
                            options={['5', '4', '3', '2', '1']} ref={el => ref.current[1] = el}
                            setValue={(value) => {
                                setResponse(2, value)
                            }} value={response[2] || null} readOnly={readOnly}/>
        <SelectionQuestions key='3' question={"3. 我对自己的学习成绩有明确的目标."} options={['5', '4', '3', '2', '1']}
                            ref={el => ref.current[2] = el}
                            setValue={(value) => {
                                setResponse(3, value)
                            }} value={response[3] || null} readOnly={readOnly}/>
        <SelectionQuestions key='4' question={"4. 我能坚持完成学习任务,直到达成目标."} options={['5', '4', '3', '2', '1']}
                            ref={el => ref.current[3] = el}
                            setValue={(value) => {
                                setResponse(4, value)
                            }} value={response[4] || null} readOnly={readOnly}/>
        <SelectionQuestions key='5' question={"5. 当学习新知识时,我感到兴奋."} options={['5', '4', '3', '2', '1']}
                            ref={el => ref.current[4] = el}
                            setValue={(value) => {
                                setResponse(5, value)
                            }} value={response[5] || null} readOnly={readOnly}/>
        <SelectionQuestions key='6' question={"6. 面对学习压力时,我能保持冷静并寻找解决方法."} options={['5', '4', '3', '2', '1']}
                            ref={el => ref.current[5] = el}
                            setValue={(value) => {
                                setResponse(6, value)
                            }} value={response[6] || null} readOnly={readOnly}/>
        <SelectionQuestions key='7' question={"7. 我能够控制自己的冲动行为和情绪反应."} options={['5', '4', '3', '2', '1']}
                            ref={el => ref.current[6] = el}
                            setValue={(value) => {
                                setResponse(7, value)
                            }} value={response[7] || null} readOnly={readOnly}/>

        <SelectionQuestions key='8' question={"8. 我有持续保持的兴趣或爱好."} options={['5', '4', '3', '2', '1']}
                            ref={el => ref.current[7] = el}
                            setValue={(value) => {
                                setResponse(8, value)
                            }} value={response[8] || null} readOnly={readOnly}/>

        <SelectionQuestions key='9' question={"9. 我愿意花时间和精力去提高自己的兴趣或爱好."} options={['5', '4', '3', '2', '1']}
                            ref={el => ref.current[8] = el}
                            setValue={(value) => {
                                setResponse(9, value)
                            }} value={response[9] || null} readOnly={readOnly}/>

        <SelectionQuestions key='10' question={"10. 我参加活动主要是出于个人兴趣,而不是外部压力."} options={['5', '4', '3', '2', '1']}
                            ref={el => ref.current[9] = el}
                            setValue={(value) => {
                                setResponse(10, value)
                            }} value={response[10] || null} readOnly={readOnly}/>

        <SelectionQuestions key='11' question={"11. 我愿意帮助他人或参与志愿工作."} options={['5', '4', '3', '2', '1']}
                            ref={el => ref.current[10] = el}
                            setValue={(value) => {
                                setResponse(11, value)
                            }} value={response[11] || null} readOnly={readOnly}/>

        <SelectionQuestions key='12' question={"12. 我能够适应不同的社交场合和社会规则."} options={['5', '4', '3', '2', '1']}
                            ref={el => ref.current[11] = el}
                            setValue={(value) => {
                                setResponse(12, value)
                            }} value={response[12] || null} readOnly={readOnly}/>

        <SelectionQuestions key='13' question={"13. 我有能力解决人际冲突."} options={['5', '4', '3', '2', '1']}
                            ref={el => ref.current[12] = el}
                            setValue={(value) => {
                                setResponse(13, value)
                            }} value={response[13] || null} readOnly={readOnly}/>

        <SelectionQuestions key='14' question={"14. 我能认真并准时完成家庭分配的任务."} options={['5', '4', '3', '2', '1']}
                            ref={el => ref.current[13] = el}
                            setValue={(value) => {
                                setResponse(14, value)
                            }} value={response[14] || null} readOnly={readOnly}/>

        <SelectionQuestions key='15' question={"15. 我会主动关心家人的需求和感受."} options={['5', '4', '3', '2', '1']}
                            ref={el => ref.current[14] = el}
                            setValue={(value) => {
                                setResponse(15, value)
                            }} value={response[15] || null} readOnly={readOnly}/>

        <SelectionQuestions key='16' question={"16. 当犯错或造成问题时,我愿意承担责任."} options={['5', '4', '3', '2', '1']}
                            ref={el => ref.current[15] = el}
                            setValue={(value) => {
                                setResponse(16, value)
                            }} value={response[16] || null} readOnly={readOnly} />

        <SelectionQuestions key='17' question={"17. 我喜欢思考不同的方法来解决问题."} options={['5', '4', '3', '2', '1']}
                            ref={el => ref.current[16] = el}
                            setValue={(value) => {
                                setResponse(17, value)
                            }} value={response[17] || null} readOnly={readOnly}/>

        <SelectionQuestions key='18' question={"18. 遇到问题时,我愿意思考多种解决方案."} options={['5', '4', '3', '2', '1']}
                            ref={el => ref.current[17] = el}
                            setValue={(value) => {
                                setResponse(18, value)
                            }} value={response[18] || null} readOnly={readOnly}/>

        <SelectionQuestions key='19' question={"19. 我有兴趣尝试新事物或接受新挑战."} options={['5', '4', '3', '2', '1']}
                            ref={el => ref.current[18] = el}
                            setValue={(value) => {
                                setResponse(19, value)
                            }} value={response[19] || null} readOnly={readOnly}/>

        <SelectionQuestions key='20' question={"20. 我对自己的学习能力有正面的评价."} options={['5', '4', '3', '2', '1']}
                            ref={el => ref.current[19] = el}
                            setValue={(value) => {
                                setResponse(20, value)
                            }} value={response[20] || null} readOnly={readOnly}/>

        <SelectionQuestions key='21' question={"21. 我认为自己有能力和价值."} options={['5', '4', '3', '2', '1']}
                            ref={el => ref.current[20] = el}
                            setValue={(value) => {
                                setResponse(21, value)
                            }} value={response[21] || null} readOnly={readOnly}/>

        <SelectionQuestions key='22' question={"22. 我能坦然接受自己的不足,并愿意改进."} options={['5', '4', '3', '2', '1']}
                            ref={el => ref.current[21] = el}
                            setValue={(value) => {
                                setResponse(22, value)
                            }} value={response[22] || null} readOnly={readOnly}/>

        <SelectionQuestions key='23' question={"23. 我深信学习是为了实现我的未来目标."} options={['5', '4', '3', '2', '1']}
                            ref={el => ref.current[22] = el}
                            setValue={(value) => {
                                setResponse(23, value)
                            }} value={response[23] || null} readOnly={readOnly}/>

        <SelectionQuestions key='24' question={"24. 我有明确的职业规划或目标."} options={['5', '4', '3', '2', '1']}
                            ref={el => ref.current[23] = el}
                            setValue={(value) => {
                                setResponse(24, value)
                            }} value={response[24] || null} readOnly={readOnly}/>

        <SelectionQuestions key='25' question={"25. 我愿意为实现人生目标投入额外时间和精力."} options={['5', '4', '3', '2', '1']}
                            ref={el => ref.current[24] = el}
                            setValue={(value) => {
                                setResponse(25, value)
                            }} value={response[25] || null} readOnly={readOnly}/>

    </div>
})

export default FirstSurvey;