import React, {useEffect} from 'react';
import "./index.scss"
import SurveyList from "../SurveyList";
import {ReactComponent as ContactLogo} from '../../assets/icon-contact.svg';
import {ReactComponent as ListLogo} from '../../assets/icon-list.svg';
import ContactPage from "../ContactPage";
import {useLocation, useNavigate} from "react-router-dom";


const Footer = () => {
    const navigate = useNavigate();
    const uuid = sessionStorage.getItem('uuid');
    const handleLogoClick = (tab: string, uuid: string | null) => {
        navigate({
            search: uuid ? `?tab=${tab}&uuid=${uuid}` : `?tab=${tab}`,
        });
    };

    return (
        <div className={"footer"}>
            <ListLogo className={'icon-list'} onClick={() => handleLogoClick('list', uuid)}/>
            <ContactLogo className={'icon-contact'} onClick={() => handleLogoClick('contact', null)}/>
        </div>
    )
}

const SurveyContainer = () => {

    const location = useLocation();
    const params = new URLSearchParams(location?.search);
    const uuid = params.get("uuid") || undefined;
    if (uuid) {
        sessionStorage.setItem('uuid', uuid);
    }
    const tab = params.get("tab") || 'default';

    const navigate = useNavigate();

    useEffect(() => {
        if ((tab === 'default' || tab === 'list') && !uuid) {
            navigate('/404', {replace: true});
        }
    }, [uuid])


    const renderContent = () => {
        switch (tab) {
            case "default":
                return <SurveyList/>;
            case "list":
                return <SurveyList/>;
            case "contact":
                return <ContactPage/>;
            default:
                navigate('/404', {replace: true});
        }
    }
    return (<>{renderContent()}<Footer/></>)

}

export default SurveyContainer;