import React from 'react';
import './index.scss';
import {ReactComponent as UncheckedIcon} from '../../../assets/stepper-unchecked.svg';
import {ReactComponent as OngoingIcon} from '../../../assets/stepper-ongoing.svg';
import {ReactComponent as CheckedIcon} from '../../../assets/stepper-checked.svg';

interface StepperProps {
    steps: string[];
    activeStep: number;
}

const Stepper: React.FC<StepperProps> = ({ steps, activeStep }) => {
    return (
        <div className="stepper">
            {steps.map((step, index) => (
                <div key={index} className="step">
                    {/* SVG Icon Placeholder */}
                    <div className={`step-icon`}>
                        {activeStep < index ? <UncheckedIcon/> : activeStep === index ? <OngoingIcon/> : <CheckedIcon/>}
                    </div>
                    <p className={`step-label  ${index === 0 || index === 1 ? 'long-text': 'short-text'} ${index <= activeStep ? 'active' : ''}`}>
                        {step}
                    </p>
                    {index !== steps.length - 1 && <div className={`step-connector ${index < activeStep ? 'active' : ''}`} />}
                </div>
            ))}
        </div>
    );
};

export default Stepper;