import React, { useState} from 'react';
import "./index.scss"
import {useRequest} from "ahooks";
import {createSurveyGroup, getAdminSG, isTokenValid} from "../../requests";
import {
    Button,
    Checkbox,
    CheckboxProps,
    Col,
    Divider,
    message,
    Modal,
    Row,
    Spin,
    Table,
    TableProps,
    Tooltip
} from "antd";
import {ReactComponent as CreateSGButton} from '../../assets/create-sg.svg';
import {ReactComponent as CreateSGButtonHovered} from '../../assets/create-sg-hover.svg';
import {ReactComponent as ModalCloseIcon} from '../../assets/modal-close-icon.svg';
import { Radio } from 'antd';
import {SURVEY_STATUS_ENUM} from "../../constants";

const Admin = () => {
    const {data: tokenValidation} = useRequest(isTokenValid)

    const [createSGButtonHovered, setCreateSGButtonHovered] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    interface DataType {
        key: number;
        link: string;
        userType: string;
        status: string;
        studentName: string
    }

    const columns: TableProps<DataType>['columns'] = [
        {
            title: '问卷链接',
            dataIndex: 'link',
            key: 'link',
            render: (link) => <div className={'index-text'}>{link}
                {link && <Button size={'large'} className={'link-button'} onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    const url = `${window.location.origin}/sg?tab=list&uuid=${link}`;
                    navigator.clipboard.writeText(url)
                        .then(() => {
                            message.success('链接已复制!')
                        })
                        .catch((err) => {
                            message.error('链接复制失败: ', err);
                        });
                }}>复制链接</Button>}
            </div>,
        },
        {
            title: '学生姓名',
            dataIndex: 'studentName',
            key: 'studentName',
            render: (studentName) => <div className={'name-text'}>{studentName}</div>,
        },
        {
            title: '家长或学生',
            dataIndex: 'userType',
            key: 'userType',
            render: (userType) => {
                const userTypeText = (userType === 'PARENT') ? '家长' : '学生'
                if (!userType) {
                    return (<div className={'name-text'}/>)
                } else {
                    return (<div className={'name-text'}>{userTypeText}</div>)
                }
            },
        },
        {
            title: '进度',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                const statusText = status === 'FINISHED' ? '已完成' : '未完成'
                if (!status) {
                    return <div className={'name-text'}></div>
                } else {
                    return (<div className={'name-text'}>{statusText}</div>)
                }

            }
        }
    ]
    const {data: surveyGroups, loading: dataLoading, run: refetch} = useRequest(getAdminSG)
    // @ts-ignore
    const tableData = surveyGroups ? surveyGroups?.flatMap((u, i) => {
        if (i !== surveyGroups.length - 1) {
            return [{
                key: u.parent_uuid,
                link: u.parent_uuid,
                userType: 'PARENT',
                status: u.parent_status,
                studentName: u.student_name,
            }, {
                key: u.student_uuid,
                link: u.student_uuid,
                userType: 'STUDENT',
                status: u.student_status,
                studentName: u.student_name,
            }, {isDisabled: true}]
        } else {
            return [{
                key: u.parent_uuid,
                link: u.parent_uuid,
                userType: 'PARENT',
                status: u.parent_status,
                studentName: u.student_name,
            }, {
                key: u.student_uuid,
                link: u.student_uuid,
                userType: 'STUDENT',
                status: u.student_status,
                studentName: u.student_name,
            }]
        }
    }) : []

    const [language, setLanguage] = useState('');
    const [studentName, setStudentName] = useState('');
    const [checkedList, setCheckedList] = useState<string[]>([]);
    const onChange = (list: string[]) => {
        setCheckedList(list);
    };

    const handleNameChange = (e:any) => {
        setStudentName(e.target.value);
    }
    const handleLanguageChange = (e:any) => {
        setLanguage(e.target.value)
    }

    return <>       {dataLoading && <Spin fullscreen size={"large"}/>}
        {!dataLoading && <div className={'overall-report-button'}
                              onClick={() => {
                                  setIsModalOpen(true)
                              }}
                              onMouseEnter={() => setCreateSGButtonHovered(true)}
                              onMouseLeave={() => setCreateSGButtonHovered(false)}>
            {createSGButtonHovered ?
                <CreateSGButtonHovered/> : <CreateSGButton/>}
        </div>}
        {!dataLoading && <Modal className={'confirm-modal sg-create-modal'} centered open={isModalOpen} footer={null}
                                closeIcon={<ModalCloseIcon className="close-icon"/>} onCancel={() => {
            setIsModalOpen(false)
        }}>
            <div>创建报告</div>
            <div>
                <Radio.Group size={'large'} value={language} onChange={handleLanguageChange}>
                    <Radio.Button value="CHN">中文</Radio.Button>
                    <Radio.Button value="ENG">英文</Radio.Button>
                </Radio.Group>
            </div>
            <div><input className={'question-input'} placeholder={'学生姓名'} value={studentName}
                        onChange={handleNameChange}/></div>
            <div className={'checkbox-container'}>
                <Checkbox.Group className={'checkbox-group'} onChange={onChange} value={checkedList}>
                    <Row>学生</Row>
                    <Row>
                        <Col span={8}>
                            <Checkbox value='1'>青少年自驱力检测表</Checkbox>
                        </Col>
                        <Col span={8}>
                            <Checkbox value="2">青少年自我探索</Checkbox>
                        </Col>
                        <Col span={8}>
                            <Checkbox value="3">青少年批判性思维能力测评</Checkbox>
                        </Col>
                        <Col span={8}>
                            <Checkbox value="4">青少年天赋探究</Checkbox>
                        </Col>
                        <Col span={8}>
                            <Checkbox value="5">青少年情商能力测评</Checkbox>
                        </Col>
                        <Col span={8}>
                            <Checkbox value="6">爱的五种语言</Checkbox>
                        </Col>
                    </Row>
                    <Row>家长</Row>
                    <Row>
                        <Col span={8}>
                            <Checkbox value="7">名校父母级别测评</Checkbox>
                        </Col>
                        <Col span={8}>
                            <Checkbox value="8">爱的五种语言</Checkbox>
                        </Col>
                    </Row>
                </Checkbox.Group>
            </div>
            <Tooltip title={(!studentName || !language || !checkedList) ? '请选择语言并填写学生姓名,选择需要做的问卷' : ''}><Button size={'large'} onClick={async () => {
                const result = await createSurveyGroup(studentName, language, checkedList);
                // @ts-ignore
                if (!result?.error) {
                    refetch();
                    message.success("问卷调查已创建,请点击复制链接并把链接发送给客户")
                    setIsModalOpen(false)
                }
            }} disabled={!studentName || !language || !checkedList}>生成报告</Button></Tooltip>
        </Modal>}
        <div className={"out-container"}>
            <div className={'list-container admin-list-container'}>
            {!dataLoading && <Table columns={columns}
                    // @ts-ignore
                                        dataSource={tableData}
                                        pagination={false}
                                        className={"survey-list__table admin-table"}
                                        rowClassName={"survey-list__table-row"}
                                        onRow={(record) => {
                                            return {
                                                onClick: () => {
                                                    // @ts-ignore
                                                    if (!record?.isDisabled) {
                                                        window.open(`/admin-survey/${record.link}`, '_blank');
                                                    }
                                                },
                                            };
                                        }}/>}
            </div>
        </div>
    </>
}

export default Admin;