import React, {useCallback, useEffect, useRef, useState} from 'react';
import "./index.scss"
import {useLocation, useNavigate} from "react-router-dom";
import {useRequest} from "ahooks";
import {getSurveyBySurveyGroupUuidAndSurveyUuid, submitSurvey, updateResponse} from "../../requests";
import {Progress, Spin} from "antd";
import {SURVEY_STATUS_ENUM, SURVEY_TYPE_MAP} from "../../constants";
import SeventhSurvey from "./SeventhSurvey";
import EighthSurvey from "./EighthSurvey";
import {ReactComponent as SubmitButton} from '../../assets/submit-button.svg';
import {ReactComponent as SubmitButtonHovered} from '../../assets/submit-button-hover.svg';
import {ReactComponent as SubmitButtonDisabled} from '../../assets/submit-button-disable.svg';
import {ReactComponent as CloseButton} from '../../assets/close-window-button.svg';
import {ReactComponent as CloseButtonHovered} from '../../assets/close-window-button-hover.svg';
import {ReactComponent as ModalCloseIcon} from '../../assets/modal-close-icon.svg';
import FirstSurvey from "./FirstSurvey";
import SecondSurvey from "./SecondSurvey";
import ThirdSurvey from "./ThirdSurvey";
import ForthSurvey from "./ForthSurvey";
import FifthSurvey from "./FifthSurvey";
import SixthSurvey from "./SixthSurvey";
import { Tooltip, Modal } from "antd";


const Survey = () => {
    const location = useLocation();
    const params = new URLSearchParams(location?.search);
    const sgid = params.get("sgid") || undefined;
    const id = params.get("id") || undefined;
    const {data, loading} = useRequest(() => getSurveyBySurveyGroupUuidAndSurveyUuid(sgid, id))
    const navigate = useNavigate();
    const surveyIndex = data?.user_type === 'PARENT' ? data?.survey_type - 6 : data?.survey_type;
    const [progress, setProgress] = useState(0);
    const [response, setResponse] = useState({});
    const questionRefs = useRef([]);
    const { run: runUpdateReponse } = useRequest(
        () =>
            updateResponse(id, response),
        {
            manual: true,
            pollingInterval: 60000
        },
    );
    const [submitButtonHovered, setSubmitButtonHovered] = useState(false);
    const [closeButtonHovered, setCloseButtonHovered] = useState(false);

    const disableButtonMessage = data && data.status === 'SUBMITTED' ? '您已提交此调查.' :'提交前请完成所有问题.';
    const [isModalOpen, setIsModalOpen] = useState(false);
    const readOnly = (data?.status !== SURVEY_STATUS_ENUM[0] && data?.status !== SURVEY_STATUS_ENUM[1]) || !!params.get("rd");
    // @ts-ignore
    const disableSubmit = (data && (SURVEY_TYPE_MAP[data?.survey_type].total !== Object.keys(response).length));

    useEffect(() => {
        if (!loading && data?.response) {
            setResponse(data.response)
        }
    }, [loading, data?.response]);


    const handleBeforeUnload = useCallback((event: any) => {
        const confirmationMessage = "Are you sure you want to leave this page? Unsaved changes might be lost.";

        event.preventDefault();
        event.returnValue = confirmationMessage; // For modern browsers
        return confirmationMessage; // For older browsers
    }, []);


    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [handleBeforeUnload]);


    const handleResponseChange = (index: number, answer: string | null) => {
        setResponse(prevResponses => {
            const newResponses = {...prevResponses};
            if (answer) {
                // @ts-ignore
                newResponses[index] = answer;
            } else {
                // @ts-ignore
                delete newResponses[index];
            }
            return newResponses;
        });
    }

    const renderContent = (ref: any) => {
        switch (data.survey_type) {
            case "default":
                return <div>default</div>
            case 1:
                return <FirstSurvey ref={ref} response={response} setResponse={handleResponseChange} readOnly={readOnly}/>
            case 2:
                return <SecondSurvey ref={ref} response={response} setResponse={handleResponseChange} readOnly={readOnly}/>
            case 3:
                return <ThirdSurvey ref={ref} response={response} setResponse={handleResponseChange} readOnly={readOnly}/>
            case 4:
                return <ForthSurvey ref={ref} response={response} setResponse={handleResponseChange} readOnly={readOnly}/>
            case 5:
                return <FifthSurvey ref={ref} response={response} setResponse={handleResponseChange} readOnly={readOnly}/>
            case 6:
                return <SixthSurvey ref={ref} response={response} setResponse={handleResponseChange} readOnly={readOnly}/>
            case 7:
                return <SeventhSurvey ref={ref} response={response} setResponse={handleResponseChange} readOnly={readOnly}/>

            case 8:
                return <EighthSurvey ref={ref} response={response} setResponse={handleResponseChange} readOnly={readOnly}/>

            default:
                navigate('/404', {replace: true});
        }
    }

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // @ts-ignore
                    const index = questionRefs.current.indexOf(entry.target as HTMLDivElement);
                    if (index !== -1) {
                        setProgress(index + 1);
                    }
                }
            });
        }, {
            threshold: 1 // Adjust this threshold as needed
        });

        questionRefs.current.forEach(question => {
            if (question) observer.observe(question);
        });

        return () => {
            questionRefs.current.forEach(question => {
                if (question) observer.unobserve(question);
            });
        };
    }, [data]);


    return <div className={"survey-container"}>
        {loading && <Spin fullscreen size={"large"}/>}
        {!loading && data && (<>
            {/*@ts-ignore*/}
            <Progress percent={Object.keys(data?.response).length / SURVEY_TYPE_MAP[data?.survey_type].total * 100}
                      strokeColor={"#DA1E28"} trailColor={"\n" +
                "rgba(193, 18, 31, 0.27)"} size={{height: 36}} className={"progress-bar"}
                      showInfo={false}/>
            <div className={"survey-header"}>问卷{surveyIndex}</div>
            {/*@ts-ignore*/}
            <div className={"survey-title"}>{SURVEY_TYPE_MAP[data?.survey_type].name}</div>
            <div className={'survey-content'}>
                <div className={"total-count"}>
                    <span className={'current-progress'}>{progress}</span>
                    {/*@ts-ignore*/}
                    <span className={'total-progress'}>/{SURVEY_TYPE_MAP[data?.survey_type].total} </span>
                </div>
                {renderContent(questionRefs)}
                {!readOnly &&<div className={"buttons"}>
                    <div className={'button-text'} onClick={async () => {
                        runUpdateReponse()
                    }}>保存进度
                    </div>
                    <div className={'button-text'} onClick={async () => {
                        runUpdateReponse();
                        window.removeEventListener("beforeunload", handleBeforeUnload);
                        window.close();
                    }}>保存并退出
                    </div>
                </div>}
            </div>
            {!readOnly &&<div className={'survey-footer'}>
                <div onClick={async () => {
                    // @ts-ignore
                    if (SURVEY_TYPE_MAP[data?.survey_type].total === Object.keys(response).length) {
                        runUpdateReponse()
                        const result = await submitSurvey(id, sgid)
                        if(!result.statusCode) {
                            setIsModalOpen(true)
                        }
                    }
                }} className={'submit-button'}
                     onMouseEnter={() => setSubmitButtonHovered(true)}
                     onMouseLeave={() => setSubmitButtonHovered(false)}>
                    {disableSubmit ?  <Tooltip title={disableButtonMessage}><SubmitButtonDisabled/></Tooltip> :submitButtonHovered ? <SubmitButtonHovered/> : <SubmitButton/>}
                </div>
            </div>}
            <Modal className={'confirm-modal'} centered open={isModalOpen} footer={null}
                   closeIcon={<ModalCloseIcon className="close-icon"/>} onCancel={()=>{setIsModalOpen(false)}}>
                <div>提交成功</div>
                <div>分析报告完成出来后会通知您</div>
                <div>
                    <div onClick={async () => {
                        window.removeEventListener("beforeunload", handleBeforeUnload);
                        window.close();
                    }} className={'close-button'}
                         onMouseEnter={() => setCloseButtonHovered(true)}
                         onMouseLeave={() => setCloseButtonHovered(false)}>
                        {closeButtonHovered ?
                            <CloseButtonHovered/> : <CloseButton/>}
                    </div>
                </div>
            </Modal>
        </>)}
    </div>
}

export default Survey;