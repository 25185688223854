export const SURVEY_TYPE_MAP = {
    1: {name:'青少年自驱力检测表', total: 25},
    2: {name:'青少年自我探索', total: 30},
    3: {name:'青少年批判性思维能力测评',total: 55},
    4: {name:'青少年天赋探究',total: 42},
    5: {name:'青少年情商能力测评',total: 35},
    6: {name:'爱的五种语言（青少年）',total: 34},
    7: {name:'名校父母级别测评',total: 36},
    8: {name:'爱的五种语言（成年人）',total: 33},
};

export const STEPPER_STEPS = ['问卷未填写','问卷填写中','已提交','已分析']
export const SURVEY_STATUS_ENUM = [
    'TO_DO',
    'IN_PROGRESS',
    'SUBMITTED',
    'REPORT_FINISHED',
];