import React from "react";
import BlankFillingQuestions from "../BlankFillingQuestions";
import './index.scss'
import SelectionQuestions from "../SelectionQuestions";

interface SixthSurveyProps {
    response: any;
    setResponse: Function;
    readOnly: boolean;
}

//爱的五种语言（青少年）
const SixthSurvey = React.forwardRef<HTMLDivElement, SixthSurveyProps>(({response, setResponse, readOnly}, ref: any) => {
    return <div className={"survey-question"}>
        <BlankFillingQuestions readOnly={readOnly} key='1' question={"1. 真实姓名/微信名"} ref={el => ref.current[0] = el}
                               setValue={(value) => {
                                   setResponse(1, value)
                               }} value={response[1] || null}/>
        <BlankFillingQuestions readOnly={readOnly} key='2' question={"2. 手机"} ref={el => ref.current[1] = el}
                               setValue={(value) => {
                                   setResponse(2, value)
                               }} value={response[2] || null}/>
        <SelectionQuestions readOnly={readOnly} key='3' question={"3. 性别"} options={['男', '女']} ref={el => ref.current[2] = el}
                            setValue={(value) => {
                                setResponse(3, value)
                            }} value={response[3] || null}/>

        <SelectionQuestions readOnly={readOnly} key='4' question={"4. B 我喜欢单独相处 \n \u00A0\u00A0\u00A0 D 当有人给予我实际帮助时,我感到被爱"}
                            options={['B', 'D']} ref={el => ref.current[3] = el}
                            setValue={(value) => {
                                setResponse(4, value)
                            }} value={response[4] || null}/>


        <SelectionQuestions readOnly={readOnly} key='5' question={"5. B 我真的很喜欢和亲友一起出去玩 \n \u00A0\u00A0\u00A0 C 我喜欢别人送我礼物"}
                            options={['B', 'C']} ref={el => ref.current[4] = el}
                            setValue={(value) => {
                                setResponse(5, value)
                            }} value={response[5] || null}/>

        <SelectionQuestions readOnly={readOnly} key='6' question={"6. E 当我亲近的人拥抱我时,我感到被爱 \n \u00A0\u00A0\u00A0 C 当我收到亲近的人送来的礼物时,我感到被爱"}
                            options={['E', 'C']} ref={el => ref.current[5] = el}
                            setValue={(value) => {
                                setResponse(6, value)
                            }} value={response[6] || null}/>

        <SelectionQuestions readOnly={readOnly} key='7' question={"7. C 礼物是爱的象征,对我来说很重要 \n \u00A0\u00A0\u00A0 A 当人们肯定我时,我感受到被爱"}
                            options={['C', 'A']} ref={el => ref.current[6] = el}
                            setValue={(value) => {
                                setResponse(7, value)
                            }} value={response[7] || null}/>

        <SelectionQuestions readOnly={readOnly} key='8' question={"8. B 我喜欢和朋友或家人共度时光 \n \u00A0\u00A0\u00A0 C 我喜欢收到来自朋友和家人的小礼物"}
                            options={['B', 'C']} ref={el => ref.current[7] = el}
                            setValue={(value) => {
                                setResponse(8, value)
                            }} value={response[8] || null}/>

        <SelectionQuestions readOnly={readOnly} key='9' question={"9. A 接受的话语对我来说意义重大 \n \u00A0\u00A0\u00A0 D 但某人帮助我时我知道他或他爱我"}
                            options={['A', 'D']} ref={el => ref.current[8] = el}
                            setValue={(value) => {
                                setResponse(9, value)
                            }} value={response[9] || null}/>

        <SelectionQuestions readOnly={readOnly} key='10' question={"10. A 我喜欢收到肯定的便条和短信 \n \u00A0\u00A0\u00A0\u00A0 E 我喜欢被拥抱"}
                            options={['A', 'E']} ref={el => ref.current[9] = el}
                            setValue={(value) => {
                                setResponse(10, value)
                            }} value={response[10] || null}/>

        <SelectionQuestions readOnly={readOnly} key='11' question={"11. B 我喜欢与朋友和家人在一起，并一起做活动 \n \u00A0\u00A0\u00A0\u00A0 A 我喜欢别人对我说善意的话语"}
                            options={['B', 'A']} ref={el => ref.current[10] = el}
                            setValue={(value) => {
                                setResponse(11, value)
                            }} value={response[11] || null}/>

        <SelectionQuestions readOnly={readOnly} key='12' question={"12. D 别人的所作所为比他所说的话对我影响更大 \n \u00A0\u00A0\u00A0\u00A0 E 拥抱比让我感受到联系和被重视"}
                            options={['D', 'E']} ref={el => ref.current[11] = el}
                            setValue={(value) => {
                                setResponse(12, value)
                            }} value={response[12] || null}/>

        <SelectionQuestions readOnly={readOnly} key='13' question={"13. A 我重视赞扬,避免批评 \n \u00A0\u00A0\u00A0\u00A0 C 对我来说,几份小礼物比一份大礼物更有意义"}
                            options={['A', 'C']} ref={el => ref.current[12] = el}
                            setValue={(value) => {
                                setResponse(13, value)
                            }} value={response[13] || null}/>

        <SelectionQuestions readOnly={readOnly} key='14' question={"14. B 当我们一起交谈或做某事时，我会感到与某人很亲近 \n \u00A0\u00A0\u00A0\u00A0\u00A0 E 当朋友和家人经常触摸我时，我会感觉和他们更亲近"}
                            options={['B', 'E']} ref={el => ref.current[13] = el}
                            setValue={(value) => {
                                setResponse(14, value)
                            }} value={response[14] || null}/>

        <SelectionQuestions readOnly={readOnly} key='15' question={"15. A 当有人赞称我的成就时,我很感激 \n\u00A0\u00A0\u00A0\u00A0\u00A0 D 当人们为我做他们不喜欢做的事情是我知道他们是爱我"}
                            options={['A', 'D']} ref={el => ref.current[14] = el}
                            setValue={(value) => {
                                setResponse(15, value)
                            }} value={response[15] || null}/>

        <SelectionQuestions readOnly={readOnly} key='16' question={"16. E 我喜欢家人或朋友用拥抱来迎接我 \n\u00A0\u00A0\u00A0\u00A0\u00A0 A 我喜欢人们听我说话并对我所说的内容,表现出真正的兴趣"}
                            options={['E', 'A']} ref={el => ref.current[15] = el}
                            setValue={(value) => {
                                setResponse(16, value)
                            }} value={response[16] || null}/>

        <SelectionQuestions readOnly={readOnly} key='17' question={"17. D 当朋友和家人在工作或项目上帮助我时,我感受到爱 \n\u00A0\u00A0\u00A0\u00A0\u00A0 C 我真的很高兴收到来自朋友和家人的礼物"}
                            options={['D', 'C']} ref={el => ref.current[16] = el}
                            setValue={(value) => {
                                setResponse(17, value)
                            }} value={response[17] || null}/>

        <SelectionQuestions readOnly={readOnly} key='18' question={"18. A 当人们称赞我的外表时,我很开心 \n\u00A0\u00A0\u00A0\u00A0\u00A0 B 当人们花时间理解我的感受时,我感受到被爱"}
                            options={['A', 'B']} ref={el => ref.current[17] = el}
                            setValue={(value) => {
                                setResponse(18, value)
                            }} value={response[18] || null}/>

        <SelectionQuestions readOnly={readOnly} key='19' question={"19. E 但我亲近的人触摸我时,我感到安全 \n\u00A0\u00A0\u00A0\u00A0\u00A0 D 服务行为让我感受到被爱"}
                            options={['E', 'D']} ref={el => ref.current[18] = el}
                            setValue={(value) => {
                                setResponse(19, value)
                            }} value={response[19] || null}/>

        <SelectionQuestions readOnly={readOnly} key='20' question={"20. D 我感激人们为我做的许多小事 \n\u00A0\u00A0\u00A0\u00A0\u00A0 C 我珍惜别人送给我的礼物"}
                            options={['D', 'C']} ref={el => ref.current[19] = el}
                            setValue={(value) => {
                                setResponse(20, value)
                            }} value={response[20] || null}/>

        <SelectionQuestions readOnly={readOnly} key='21' question={"21. B 我非常享受一对一全神贯注的体验 \n\u00A0\u00A0\u00A0\u00A0\u00A0 D 当有人为我提供帮助时,我真的很开心"}
                            options={['B', 'D']} ref={el => ref.current[20] = el}
                            setValue={(value) => {
                                setResponse(21, value)
                            }} value={response[21] || null}/>

        <SelectionQuestions readOnly={readOnly} key='22' question={"22. E 当人们触摸我时,我感受到被爱 \n\u00A0\u00A0\u00A0\u00A0\u00A0 D 当人们做一些事情帮助我时,我感受到被爱"}
                            options={['E', 'D']} ref={el => ref.current[21] = el}
                            setValue={(value) => {
                                setResponse(22, value)
                            }} value={response[22] || null}/>

        <SelectionQuestions readOnly={readOnly} key='23' question={"23. C 在我生日收到礼物时,我感到被爱 \n\u00A0\u00A0\u00A0\u00A0\u00A0 A 当我生日有人对我说一些有意义的话时,我感到被爱爱"}
                            options={['C', 'A']} ref={el => ref.current[22] = el}
                            setValue={(value) => {
                                setResponse(23, value)
                            }} value={response[23] || null}/>

        <SelectionQuestions readOnly={readOnly} key='24' question={"24. C 当某人送我礼物时,我知道他或他正在想着我 \n\u00A0\u00A0\u00A0\u00A0\u00A0 D 当有人帮我做家务时,我感到被爱"}
                            options={['C', 'D']} ref={el => ref.current[23] = el}
                            setValue={(value) => {
                                setResponse(24, value)
                            }} value={response[24] || null}/>

        <SelectionQuestions readOnly={readOnly} key='25' question={"25. B 我喜欢与亲近的人一起去一些地方 \n\u00A0\u00A0\u00A0\u00A0\u00A0 E 当我喜欢某人食物会与他进行拥抱,击掌,握手"}
                            options={['B', 'E']} ref={el => ref.current[24] = el}
                            setValue={(value) => {
                                setResponse(25, value)
                            }} value={response[25] || null}/>

        <SelectionQuestions readOnly={readOnly} key='26' question={"26. A 如果有人能耐心倾听,并且不打断我说话,我会非常感激 \n\u00A0\u00A0\u00A0\u00A0\u00A0 C 当有人用礼物来纪念特殊的日子是我会很感激"}
                            options={['A', 'C']} ref={el => ref.current[25] = el}
                            setValue={(value) => {
                                setResponse(26, value)
                            }} value={response[26] || null}/>

        <SelectionQuestions readOnly={readOnly} key='27' question={"27. D 我很高兴知道人们足够关心我,愿意帮助我完成日常任务 \n\u00A0\u00A0\u00A0\u00A0\u00A0 B 我喜欢与亲近的人一起进行全天冒险或旅行"}
                            options={['D', 'B']} ref={el => ref.current[26] = el}
                            setValue={(value) => {
                                setResponse(27, value)
                            }} value={response[27] || null}/>

        <SelectionQuestions readOnly={readOnly} key='28' question={"28. E 得到父母或长辈的亲吻,会让我感受到爱 \n\u00A0\u00A0\u00A0\u00A0\u00A0 C 受到父母或长辈无缘无故送的礼物,让我感受到爱"}
                            options={['E', 'C']} ref={el => ref.current[27] = el}
                            setValue={(value) => {
                                setResponse(28, value)
                            }} value={response[28] || null}/>

        <SelectionQuestions readOnly={readOnly} key='29' question={"29. A 我喜欢别人对我表示赞赏 \n\u00A0\u00A0\u00A0\u00A0\u00A0 B 我喜欢人们在交谈时看着我"}
                            options={['A', 'B']} ref={el => ref.current[28] = el}
                            setValue={(value) => {
                                setResponse(29, value)
                            }} value={response[29] || null}/>

        <SelectionQuestions readOnly={readOnly} key='30' question={"30. B 我喜欢坐在我喜欢的人旁边 \n\u00A0\u00A0\u00A0\u00A0\u00A0 A 但有人告诉我我有多有魅力时,我会很感激"}
                            options={['B', 'A']} ref={el => ref.current[29] = el}
                            setValue={(value) => {
                                setResponse(30, value)
                            }} value={response[30] || null}/>

        <SelectionQuestions readOnly={readOnly} key='31' question={"31. C 亲友送的礼物对我来说总是很特别 \n\u00A0\u00A0\u00A0\u00A0\u00A0 E 亲友触摸我时,我会感觉很好"}
                            options={['C', 'E']} ref={el => ref.current[30] = el}
                            setValue={(value) => {
                                setResponse(31, value)
                            }} value={response[31] || null}/>

        <SelectionQuestions readOnly={readOnly} key='32' question={"32. D 当有人热情的完成我要求的任务时我感到爱 \n\u00A0\u00A0\u00A0\u00A0\u00A0 A 当别人告诉我,他们非常赏识我时,我感觉自己被爱着"}
                            options={['D', 'A']} ref={el => ref.current[31] = el}
                            setValue={(value) => {
                                setResponse(32, value)
                            }} value={response[32] || null}/>

        <SelectionQuestions readOnly={readOnly} key='33' question={"33. E 我需要每天都被拥抱触摸 \n\u00A0\u00A0\u00A0\u00A0\u00A0 A 我每天都需要肯定的话语"}
                            options={['E', 'A']} ref={el => ref.current[32] = el}
                            setValue={(value) => {
                                setResponse(33, value)
                            }} value={response[33] || null}/>

        <SelectionQuestions readOnly={readOnly} key='34' question={"34. C 我喜欢别人送我礼物 \n\u00A0\u00A0\u00A0\u00A0\u00A0 D 别人所作所为比他所说的话，对我影响更大"}
                            options={['C', 'D']} ref={el => ref.current[33] = el}
                            setValue={(value) => {
                                setResponse(34, value)
                            }} value={response[34] || null}/>
    </div>
})

export default SixthSurvey;