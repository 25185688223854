import React from "react";
import './index.scss'
import SelectionQuestions from "../SelectionQuestions";

interface FifthSurveyProps {
    response: any;
    setResponse: Function;
    readOnly: boolean;
}

//青少年情商能力测评
const FifthSurvey = React.forwardRef<HTMLDivElement, FifthSurveyProps>(({response, setResponse, readOnly}, ref: any) => {
    return <div className={"survey-question"}>
        <SelectionQuestions readOnly={readOnly} key='1' question={"1. 当你感到生气时,你会尝试以下哪项?"}
                            options={['深呼吸或冷静下来', '与他人发生争执', '不做任何改变', '将情绪表达出来,寻求解决方案', '把情绪藏在心里,不和别人讲']}
                            ref={el => ref.current[0] = el}
                            setValue={(value) => {
                                setResponse(1, value)
                            }} value={response[1] || null}/>

        <SelectionQuestions readOnly={readOnly} key='2' question={"2. 面对挫折或困难时,你是否尝试以下哪项?"}
                            options={['积极寻求解决方案', '抱怨和放弃', '随遇而安,不做改变', '寻求他人帮助,共同解决问题', '将问题扩大化,觉得一切都很糟糕']}
                            ref={el => ref.current[1] = el}
                            setValue={(value) => {
                                setResponse(2, value)
                            }} value={response[2] || null}/>

        <SelectionQuestions readOnly={readOnly} key='3' question={"3. 当你感到沮丧或情绪低落时,你是否寻求以下哪项?"}
                            options={['与朋友或家人倾诉', '独自处理,不分享感受', '转移注意力,不去想它', '寻找解决问题的方法,让自己快乐起来', '自我安慰,告诉自己一切都会好起来的']}
                            ref={el => ref.current[2] = el}
                            setValue={(value) => {
                                setResponse(3, value)
                            }} value={response[3] || null}/>

        <SelectionQuestions readOnly={readOnly} key='4' question={"4. 面对考试或重要活动,你是否尝试以下哪项?"}
                            options={['自我激励和保持积极心态', '担心和自我怀疑', '不关心结果,无所谓成败', '认真备考,准备充足', '因紧张而放弃准备,觉得考不好无所谓']}
                            ref={el => ref.current[3] = el}
                            setValue={(value) => {
                                setResponse(4, value)
                            }} value={response[4] || null}/>

        <SelectionQuestions readOnly={readOnly} key='5' question={"5. 你在交流时是否倾向于使用以下哪种表达方式?"}
                            options={['我觉得...,我需要...', '你总是...,你永远...', '不表达,保持沉默', '委婉地表达自己的意见', '直接表达自己的不满和愤怒']}
                            ref={el => ref.current[4] = el}
                            setValue={(value) => {
                                setResponse(5, value)
                            }} value={response[5] || null}/>

        <SelectionQuestions readOnly={readOnly} key='6' question={"6. 当遇到挑战或压力时,你是否尝试以下哪项?"}
                            options={['积极应对,保持乐观心态', '逃避或退缩', '自我责备和否认问题', '寻求他人帮助,共同应对挑战', '觉得自己承受不了,对问题束手无策']}
                            ref={el => ref.current[5] = el}
                            setValue={(value) => {
                                setResponse(6, value)
                            }} value={response[6] || null}/>

        <SelectionQuestions readOnly={readOnly} key='7' question={"7. 面对不愉快的情况,你是否尝试以下哪项?"}
                            options={['采用积极的应对方式', '假装没事,逃避问题', '自责或责怪他人', '换个角度看问题,寻找积极面', '把不愉快的情况深埋心底,不愿面对']}
                            ref={el => ref.current[6] = el}
                            setValue={(value) => {
                                setResponse(7, value)
                            }} value={response[7] || null}/>

        <SelectionQuestions readOnly={readOnly} key='8' question={"8. 当他人向你道歉时,你是否尝试以下哪项?"}
                            options={['接受并原谅', '不接受,持怀疑态度', '不在意,觉得无所谓', '表示感谢对方的道歉', '不理会道歉,保持冷漠']}
                            ref={el => ref.current[7] = el}
                            setValue={(value) => {
                                setResponse(8, value)
                            }} value={response[8] || null}/>

        <SelectionQuestions readOnly={readOnly} key='9' question={"9. 你是否愿意给予他人鼓励和支持?"}
                            options={['是,乐于帮助他人', '不太在意别人的感受', '不愿意给予鼓励', '经常鼓励他人,希望他们开心', '觉得鼓励别人没用,不愿付出']}
                            ref={el => ref.current[8] = el}
                            setValue={(value) => {
                                setResponse(9, value)
                            }} value={response[9] || null}/>

        <SelectionQuestions readOnly={readOnly} key='10' question={"10. 面对不同情绪,你是否愿意接受自己的感受?"}
                            options={['是,接受自己的情绪', '隐藏或压抑自己的情绪', '无法分辨自己的情绪', '尝试去理解自己的情绪,但有时还是难以接受', '完全不理会自己的情绪']}
                            ref={el => ref.current[9] = el}
                            setValue={(value) => {
                                setResponse(10, value)
                            }} value={response[10] || null}/>

        <SelectionQuestions readOnly={readOnly} key='11' question={"11. 当你感到焦虑时,你是否尝试以下哪项?"}
                            options={['采取冷静思考,找出解决问题的方法', '向他人诉说,寻求安慰和支持', '忽略焦虑感受,自我麻痹', '试图逃避与焦虑相关的事物或场景', '尝试放松技巧,如冥想或深呼吸']}
                            ref={el => ref.current[10] = el}
                            setValue={(value) => {
                                setResponse(11, value)
                            }} value={response[11] || null}/>

        <SelectionQuestions readOnly={readOnly} key='12' question={"12. 面对成功或受到表扬时,你是否尝试以下哪项?"}
                            options={['享受成就感,自信满满', '觉得自己还有不足,害怕被人忽视', '平静对待,不受影响', '自卑或羡慕他人', '将成功看得过于重要,过分炫耀']}
                            ref={el => ref.current[11] = el}
                            setValue={(value) => {
                                setResponse(12, value)
                            }} value={response[12] || null}/>

        <SelectionQuestions readOnly={readOnly} key='13' question={"13. 你是否愿意主动寻求新的学习和成长机会?"}
                            options={['是,我喜欢学习新知识和技能', '不太在意学习,觉得学校功课够了就行', '只有在必要时才学习新东西', '害怕挑战,不愿尝试新事物', '为了顺应他人期望而学习,而不是出于兴趣']}
                            ref={el => ref.current[12] = el}
                            setValue={(value) => {
                                setResponse(13, value)
                            }} value={response[13] || null}/>

        <SelectionQuestions readOnly={readOnly} key='14' question={"14. 面对自己的错误或失败,你是否尝试以下哪项?"}
                            options={['勇敢地承认错误,寻求改进', '掩饰错误,不愿意承认', '自责和怀疑自己的能力', '将错误归咎于他人,不愿意反省', '对错误视而不见,不想去面对']}
                            ref={el => ref.current[13] = el}
                            setValue={(value) => {
                                setResponse(14, value)
                            }} value={response[14] || null}/>

        <SelectionQuestions readOnly={readOnly} key='15' question={"15. 你是否能够在团队合作中有效地与他人合作?"}
                            options={['是,我能够倾听他人意见并积极参与', '我更喜欢独自完成任务,不太擅长合作', '我会被他人的意见影响,缺乏主见', '我对他人意见不太在意,喜欢按自己的方式做', '我害怕与他人合作,怕被拒绝或批评']}
                            ref={el => ref.current[14] = el}
                            setValue={(value) => {
                                setResponse(15, value)
                            }} value={response[15] || null}/>

        <SelectionQuestions readOnly={readOnly} key='16' question={"16. 面对不同情绪,你是否尝试以下哪项?"}
                            options={['保持积极乐观,努力调整情绪', '将情绪深埋心底,不愿表达', '表现出冷漠和无动于衷', '情绪波动大,难以自控', '将情绪发泄在他人身上,失去理智']}
                            ref={el => ref.current[15] = el}
                            setValue={(value) => {
                                setResponse(16, value)
                            }} value={response[16] || null}/>

        <SelectionQuestions readOnly={readOnly} key='17' question={"17. 你是否愿意给予他人鼓励和支持?"}
                            options={['是,乐于帮助他人', '不太在意别人的感受', '不愿意给予鼓励', '经常鼓励他人,希望他们开心', '觉得鼓励别人没用,不愿付出']}
                            ref={el => ref.current[16] = el}
                            setValue={(value) => {
                                setResponse(17, value)
                            }} value={response[17] || null}/>

        <SelectionQuestions readOnly={readOnly} key='18' question={"18. 在追求个人目标时,你是否坚持不懈?"}
                            options={['是,我会全力以赴,无论遇到什么困难', '我会犹豫不决,担心失败', '我很容易放弃,缺乏坚持力', '我因他人的看法而放弃自己的目标', '我喜欢追求新的目标,但难以长期坚持']}
                            ref={el => ref.current[17] = el}
                            setValue={(value) => {
                                setResponse(18, value)
                            }} value={response[18] || null}/>

        <SelectionQuestions readOnly={readOnly} key='19' question={"19. 当你遇到一件令人高兴的事情时,你会感受到以下哪种情绪?"}
                            options={['快乐和兴奋', '不确定和害怕', '无所谓,冷漠', '焦虑和不安', '愤怒和恼火']}
                            ref={el => ref.current[18] = el}
                            setValue={(value) => {
                                setResponse(19, value)
                            }} value={response[19] || null}/>

        <SelectionQuestions readOnly={readOnly} key='20' question={"20. 当你遭遇挫折或失败时,你会感受到以下哪种情绪?"}
                            options={['悲伤和失望', '乐观和自信', '愤怒和愧疚', '紧张和恐惧', '不在意,轻松面对']}
                            ref={el => ref.current[19] = el}
                            setValue={(value) => {
                                setResponse(20, value)
                            }} value={response[20] || null}/>

        <SelectionQuestions readOnly={readOnly} key='21' question={"21. 当你被他人批评或责怪时,你会感受到以下哪种情绪?"}
                            options={['生气和愤怒', '伤心和沮丧', '不在意,不受影响', '不安和自责', '焦虑和紧张']}
                            ref={el => ref.current[20] = el}
                            setValue={(value) => {
                                setResponse(21, value)
                            }} value={response[21] || null}/>

        <SelectionQuestions readOnly={readOnly} key='22' question={"22. 当你在人群中感到孤独或被排斥时,你会感受到以下哪种情绪?"}
                            options={['孤独和失落', '自信和独立', '不在意,无所谓', '焦虑和不安', '愤怒和受伤']}
                            ref={el => ref.current[21] = el}
                            setValue={(value) => {
                                setResponse(22, value)
                            }} value={response[22] || null}/>

        <SelectionQuestions readOnly={readOnly} key='23' question={"23. 当你面临未知的事物或新的挑战时,你会感受到以下哪种情绪?"}
                            options={['紧张和恐惧', '乐观和好奇', '不在意,漠不关心', '焦虑和犹豫', '自信和坦然']}
                            ref={el => ref.current[22] = el}
                            setValue={(value) => {
                                setResponse(23, value)
                            }} value={response[23] || null}/>

        <SelectionQuestions readOnly={readOnly} key='24' question={"24. 当你觉得别人对你不友善或有敌意时,你会感受到以下哪种情绪?"}
                            options={['愤怒和恼火', '伤心和失望', '不在意,不受影响', '焦虑和自卑', '冷漠和无所谓']}
                            ref={el => ref.current[23] = el}
                            setValue={(value) => {
                                setResponse(24, value)
                            }} value={response[24] || null}/>

        <SelectionQuestions readOnly={readOnly} key='25' question={"25. 当你感到紧张或不安时,你能准确地辨认出自己的情绪是由于以下哪种原因引起的吗?"}
                            options={['未来的不确定性', '他人的期望和压力', '过去的经历和回忆', '当前的挑战和困难', '自己的内心焦虑和担忧']}
                            ref={el => ref.current[24] = el}
                            setValue={(value) => {
                                setResponse(25, value)
                            }} value={response[25] || null}/>

        <SelectionQuestions readOnly={readOnly} key='26' question={"26. 当你感到生气或愤怒时,你能准确地辨认出自己是因为以下哪种原因而感受到这种情绪吗?"}
                            options={['别人的冒犯和不尊重', '自己的无能和失败', '环境的不公和不满', '他人的干扰和打扰', '自己的情绪不稳定和无法控制']}
                            ref={el => ref.current[25] = el}
                            setValue={(value) => {
                                setResponse(26, value)
                            }} value={response[26] || null}/>

        <SelectionQuestions readOnly={readOnly} key='27' question={"27. 当你感到焦虑或紧张时,你能准确地辨认出自己是因为以下哪种原因而感受到这种情绪吗?"}
                            options={['未来的不确定性和担忧', '当前的挑战和压力', '过去的失败和困境', '他人的评价和批评', '自己对自身能力的怀疑和质疑']}
                            ref={el => ref.current[26] = el}
                            setValue={(value) => {
                                setResponse(27, value)
                            }} value={response[27] || null}/>

        <SelectionQuestions readOnly={readOnly} key='28' question={"28. 当你感到兴奋和愉快时,你能准确地辨认出自己是因为以下哪种原因而感受到这种情绪吗?"}
                            options={['实现自己的目标和愿望', '他人的认可和赞扬', '进入一个美好的环境和时刻', '逃避一些困扰和问题', '对未来的期望和希望']}
                            ref={el => ref.current[27] = el}
                            setValue={(value) => {
                                setResponse(28, value)
                            }} value={response[28] || null}/>

        <SelectionQuestions readOnly={readOnly} key='29' question={"29. 你最近遇到压力大的情况时,你的反应是什么?"}
                            options={['尽量避免', '找人倾诉', '试图自我安慰,比如说服自己一切都会好的', '采取行动来解决问题', '深呼吸或进行其他的放松活动']}
                            ref={el => ref.current[28] = el}
                            setValue={(value) => {
                                setResponse(29, value)
                            }} value={response[29] || null}/>

        <SelectionQuestions readOnly={readOnly} key='30' question={"30. 当你与朋友产生意见不合时,你会如何处理?"}
                            options={['不理睬他们,让时间解决问题', '强烈的表达自己的观点,试图说服他们', '倾听他们的观点,然后陈述自己的想法', '全部接受他们的观点,以避免冲突', '提议大家一起找一个妥协的方案']}
                            ref={el => ref.current[29] = el}
                            setValue={(value) => {
                                setResponse(30, value)
                            }} value={response[30] || null}/>

        <SelectionQuestions readOnly={readOnly} key='31' question={"31. 当你感到沮丧或难过时,你会如何处理你的情绪?"}
                            options={['把情绪憋在心里', '大哭一场,让情绪得到宣泄', '尝试去理解和接受这种情绪', '找朋友倾诉', '通过做自己喜欢的事情来转移注意力']}
                            ref={el => ref.current[30] = el}
                            setValue={(value) => {
                                setResponse(31, value)
                            }} value={response[31] || null}/>

        <SelectionQuestions readOnly={readOnly} key='32' question={"32. 当你在学校或家庭中做出一些重要决策时,你会怎么做?"}
                            options={['总是按照别人的建议', '仅仅依靠自己的感觉和直觉', '考虑各种可能的后果和结果,然后做决定', '倾向于避免做决定,以免产生后果', '在收集足够的信息并听取他人意见后做出决定']}
                            ref={el => ref.current[31] = el}
                            setValue={(value) => {
                                setResponse(32, value)
                            }} value={response[32] || null}/>

        <SelectionQuestions readOnly={readOnly} key='33' question={"33. 当你遇到困难或挑战时,你如何看待这种情况?"}
                            options={['觉得自己很无能,不能解决问题', '会觉得有些紧张和不安,但还是会尝试去解决', '觉得这是个机会,可以学习和成长', '责怪他人或环境,认为这不是自己的责任', '找到应对策略,积极解决问题']}
                            ref={el => ref.current[32] = el}
                            setValue={(value) => {
                                setResponse(33, value)
                            }} value={response[33] || null}/>

        <SelectionQuestions readOnly={readOnly} key='34' question={"34. 你通常如何处理自己对他人的期待和要求?"}
                            options={['我通常不对别人有任何期待或要求', '我的期待和要求通常非常高,我希望他人能满足我的期待', '我会清楚地向他人表达我的期待和要求,但是我也理解他们可能无法满足', '我的期待和要求通常比较模糊,我不太善于表达', '我会明确我的期待,但也会给予他人自由,接受他们可能无法满足我的期待']}
                            ref={el => ref.current[33] = el}
                            setValue={(value) => {
                                setResponse(34, value)
                            }} value={response[34] || null}/>

        <SelectionQuestions readOnly={readOnly} key='35' question={"35. 当你感到紧张或焦虑时,你通常会怎么做?"}
                            options={['把情绪压抑在心里', '找朋友或家人倾诉', '尝试理解这种感觉并接受它', '通过运动或者做一些喜欢的事情来转移注意力', '采用深呼吸、冥想等放松技巧来帮助自己平静下来']}
                            ref={el => ref.current[34] = el}
                            setValue={(value) => {
                                setResponse(35, value)
                            }} value={response[35] || null}/>
    </div>
})

export default FifthSurvey;