import React, {useState} from 'react';
import "./index.scss"
import {useParams} from "react-router-dom";
import {useRequest} from "ahooks";
import {fetchSurveyReports, isTokenValid} from "../../../requests";
import RichTextEditor from "../../RichTextEditor";
import {SURVEY_TYPE_MAP} from "../../../constants";
import {SixthSurveyReport} from "../AdminSurveyReport";
import {Spin} from "antd";
import {ReactComponent as ExportButton} from '../../../assets/export-button.svg';
import {ReactComponent as ExportButtonHovered} from '../../../assets/export-button-hover.svg';
import {ReactComponent as ExportingButton} from '../../../assets/exporting.svg';


const AdminAllReport = () => {
    const {data: tokenValidation} = useRequest(isTokenValid)
    const params = useParams()
    // @ts-ignore
    const {data: reports, loading: dataLoading} = useRequest(() => fetchSurveyReports(params.uuid))
    const [loading, setLoading] = useState(false);
    const [exportButtonHovered, setExportButtonHovered] = useState(false);


    const generatePdf = async () => {
        setLoading(true);
        try {
            const response = await fetch(`https://yutongguihua.com/api/pdf/generate?url=${encodeURIComponent(`https://www.yutongguihua.com/report-pdf/${params.uuid}`)}`, {
                method: 'POST', // or 'POST' depending on your backend implementation
                body: JSON.stringify({token: localStorage.getItem('token')}),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to generate PDF: ${response.statusText}`);
            }

            const blob = await response.blob(); // Get the response as a Blob (binary data)

            // Create a URL for the Blob and trigger a download
            const url = window.URL.createObjectURL(new Blob([blob], {type: 'application/pdf'}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'generated_report.pdf'); // Set the download file name
            document.body.appendChild(link);
            link.click(); // Programmatically click the link to trigger the download

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url); // Release memory
        } catch (error) {
            console.error('Error generating PDF:', error);
        } finally {
            setLoading(false);
        }
    };

    return <div className={'all-report'}>
        {dataLoading && <Spin fullscreen size={"large"}/>}
        {/*@ts-ignore*/}
        {!dataLoading && reports && reports.map((u, index) => {
            if (u.survey_type === 6 || u.survey_type === 8) {
                return <>
                    {/*@ts-ignore*/}
                    <div className={'title'}>{`${SURVEY_TYPE_MAP[u.survey_type]?.name}分析结果`}</div>
                    <SixthSurveyReport report={u['gpt_result']}/>  </>
            } else {
                return <>
                    {/*@ts-ignore*/}
                    <div className={'title'}>{`${SURVEY_TYPE_MAP[u.survey_type]?.name}分析结果`}</div>
                    <div key={index}><RichTextEditor initText={u['gpt_result']} editable={false}/></div>
                </>
            }
        })
        }
        {!dataLoading && <div className={'generate-pdf-button'}
                              onClick={() => {
                                  if (!loading) {
                                      generatePdf()
                                  }
                              }}
                              onMouseEnter={() => setExportButtonHovered(true)}
                              onMouseLeave={() => setExportButtonHovered(false)}>
            {loading ? <ExportingButton/> : exportButtonHovered ?
                <ExportButtonHovered/> : <ExportButton/>}
        </div>
        }
    </div>
}

export default AdminAllReport