import React from 'react';
import "./index.scss"
import { ReactComponent as HeaderLogo } from '../../assets/header.svg';


const Header = () => {
    return <div id={'header'} className={`header ${window.location.pathname === '/survey'? 'header-white' : ''}`}>
        <HeaderLogo/>
    </div>
}

export default Header;